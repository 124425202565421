/* Verein */
const Verein = () => import('../components/admin/verein/index.vue')
const VereinBearbeiten = () =>
  import('../components/admin/verein/edit-verein.vue')
const VereinUebersicht = () => import('../components/admin/verein/start.vue')
const CreateUser = () => import('../components/admin/verein/createUser.vue')
const VereinPersonen = () =>
  import('../components/admin/verein/personenverwaltung.vue')
const PersonenImport = () =>
  import('../components/admin/verein/personen_csvimport.vue')
const VereinAddPerson = () =>
  import('../components/admin/verein/createPerson.vue')
const VereinBenutzer = () =>
  import('../components/admin/verein/userverwaltung.vue')
const VereinBenutzerEdit = () =>
  import('../components/admin/verein/userEdit.vue')
const SportstaettenVerwalten = () =>
  import('../components/admin/verein/sportstaetten.vue')
const SportstaetteVerwalten = () =>
  import('../components/admin/verein/sportstaette.vue')
const HygienekonzeptBearbeiten = () =>
  import('../components/admin/verein/hygienekonzept.vue')
const AbteilungenVerwalten = () =>
  import('../components/admin/verein/abteilungen.vue')
const AbteilungBearbeiten = () =>
  import('../components/admin/verein/abteilung.vue')
const AktivitaetenVerwalten = () =>
  import('../components/admin/verein/aktivitaeten.vue')
const AktivitaetBearbeiten = () =>
  import('../components/admin/verein/aktivitaet.vue')
const GAconnect = () => import('../components/admin/verein/iris-config.vue')

const vereinroutes = {
  path: '/verein',
  component: Verein,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '',
      component: VereinUebersicht,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'personen',
      component: VereinPersonen,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'personen/csvimport',
      component: PersonenImport,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'erstelle-person',
      component: VereinAddPerson,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'benutzerverwaltung',
      component: VereinBenutzer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'benutzerverwaltung/:benutzer',
      component: VereinBenutzerEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'bearbeiten',
      component: VereinBearbeiten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'erstelle-benutzer',
      component: CreateUser,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'sportstaetten',
      component: SportstaettenVerwalten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'abteilungen',
      component: AbteilungenVerwalten,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: 'abteilungen/:abteilung',
      component: AbteilungBearbeiten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'aktivitaeten',
      component: AktivitaetenVerwalten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'aktivitaeten/:aktivitaet',
      component: AktivitaetBearbeiten,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: 'hygienekonzept',
      component: HygienekonzeptBearbeiten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'sportstaetten/:sportstaette',
      component: SportstaetteVerwalten,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'ga-connect',
      component: GAconnect,
      meta: {
        requiresAuth: true,
      },
    },
  ],
}

export default vereinroutes
