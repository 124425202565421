/* MODULES */
import Vue from 'vue'
import Router from 'vue-router'
import auth from '../auth/auth'

/* ROUTE-COMPONENTS */
import adminroutes from './admin'
import userroutes from './user'
import staticroutes from './staticsites'
import publicroutes from './publicsites'

Vue.use(Router)

const routes = [
  adminroutes.admin,
  userroutes.login,
  userroutes.passwordforget,
  userroutes.passwordreset,
  userroutes.register,
  userroutes.refereeregister,
  userroutes.user,
  staticroutes.impressum,
  staticroutes.privacy,
  staticroutes.releasenotes,
  publicroutes.guestteams,
  publicroutes.wettkampf,
  publicroutes.verein,
  publicroutes.pagenotfound,
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next()
})

export default router
