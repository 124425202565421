/* Zuschauer-Account */
const Zuschauer = () => import('../components/zuschauer/index.vue')
const Tickets = () => import('../components/zuschauer/tickets.vue')
const Ticket = () => import('../components/zuschauer/ticket.vue')
const Besuche = () => import('../components/zuschauer/besuche.vue')
const Account = () => import('../components/zuschauer/account.vue')
const QRScan = () => import('../components/zuschauer/qrcodescan.vue')

const Login = () => import('../components/login/login.vue')
const PasswortVergessen = () => import('../components/login/passwordreset.vue')
const PasswortZuruecksetzen = () =>
  import('../components/login/setnewpassword.vue')
const SignUp = () => import('../components/registrierung/signup.vue')
const RefSignUp = () =>
  import('../components/registrierung/signup_schiedsrichter.vue')

const userroutes = {
  login: {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  passwordforget: {
    path: '/passwort-vergessen',
    name: 'Passwort vergessen',
    component: PasswortVergessen,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  passwordreset: {
    path: '/passwort-zuruecksetzen',
    name: 'Passwort zurücksetzen',
    component: PasswortZuruecksetzen,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  register: {
    path: '/registrierung',
    name: 'Registrierung',
    component: SignUp,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  refereeregister: {
    path: '/schiedsrichter-registrierung',
    name: 'Schiedsrichter-Registrierung',
    component: RefSignUp,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  user: {
    path: '/user',
    name: 'Vereinsseite',
    component: Zuschauer,
    meta: {
      requiresAuth: true,
      allowUnverified: true,
    },
    children: [
      {
        path: '',
        name: 'Mein Account',
        component: Account,
        meta: {
          requiresAuth: true,
          allowUnverified: false,
        },
      },
      {
        path: 'tickets',
        name: 'Meine Tickets',
        component: Tickets,
        meta: {
          requiresAuth: true,
          allowUnverified: false,
        },
      },
      {
        path: 'tickets/:ticket',
        name: 'Meine Tickets',
        component: Ticket,
        meta: {
          requiresAuth: true,
          allowUnverified: false,
        },
      },
      {
        path: 'check-ins',
        name: 'Meine CheckIns',
        component: Tickets,
        meta: {
          requiresAuth: true,
          allowUnverified: false,
        },
      },
      {
        path: 'besuche',
        name: 'Meine Besuche',
        component: Besuche,
        meta: {
          requiresAuth: true,
          allowUnverified: false,
        },
      },
      {
        path: 'scan',
        name: 'Link-Scan',
        component: QRScan,
        meta: {
          requiresAuth: true,
          allowUnverified: false,
        },
      },
    ],
  },
}

export default userroutes
