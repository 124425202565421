export default class Verein {
  id = ''
  name = ''
  logo = ''
  alias = ''
  hygienekonzept = ''
  verband = ''

  constructor(id, name, logo, alias, hygienekonzept, verband) {
    this.id = id
    this.name = name
    this.logo = logo
    this.alias = alias
    this.hygienekonzept = hygienekonzept
    this.verband = verband
  }

  getId() {
    return this.id
  }

  createFromObject(obj) {
    if (obj instanceof Object) {
      this.id = obj.id
      this.name = obj.verein.name
      this.logo = obj.verein.logo
      this.alias = obj.alias
      this.hygienekonzept = obj.hygienekonzept
      this.verband = obj.verband
    }
  }

  toJSON() {
    return {
      id: this.id ? this.id : '',
      name: this.name ? this.name : '',
      logo: this.logo ? this.logo : '',
      alias: this.alias ? this.alias : '',
      hygienekonzept: this.hygienekonzept ? this.hygienekonzept : '',
      verband: this.verband ? this.verband : '',
    }
  }
}
