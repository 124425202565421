export default class Zeit {
  datum = ''
  uhrzeit = '00:00:00'
  timestamp = ''

  constructor(datum = '', uhrzeit = '', timestamp = '') {
    if (timestamp) {
      this.setFromObject(new Date(timestamp))
    } else if (datum && uhrzeit) {
      this.setDatumUhrzeit(datum, uhrzeit)
    } else {
      this.now()
    }
  }

  now() {
    this.setFromObject(new Date(Date.now()))
  }

  ZeitObjectFromIRIS() {
    var date = new Date(this.timestamp)
    date.setHours(date.getHours() + 2)
    this.setFromObject(date)
  }

  ZeitObjectToIRIS() {
    var date = new Date(this.timestamp)
    date.setHours(date.getHours() - 2)
    this.setFromObject(date)
  }

  static generateDays(from, to) {
    from = from * -1
    var daten = []
    var tage = []
    for (var i = from; i <= to; i++) {
      var datum = new Date(Date.now())
      datum.setDate(datum.getDate() + i)
      daten.push(this.generateFromObject(datum))
    }
    daten.forEach((tag) => {
      tage.push(tag.getDatum())
    })
    return {
      zeitobjekt: daten,
      tage: tage,
    }
  }

  /**
   * Ändert Datum, Uhrzeit und Timestamp des Zeit-Objektes
   *
   * @param {*} date : Date
   */
  static generateFromObject(date) {
    var datum = ''
    var uhrzeit = ''
    var timestamp = ''

    if (date instanceof Date) {
      datum =
        date.getFullYear() +
        '-' +
        this.add_null(date.getMonth() + 1) +
        '-' +
        this.add_null(date.getDate())
      uhrzeit =
        this.add_null(date.getHours()) +
        ':' +
        this.add_null(date.getMinutes()) +
        ':' +
        this.add_null(date.getSeconds())
      timestamp = date.getTime()
    }
    return new Zeit(datum, uhrzeit, timestamp)
  }

  /**
   * Ändert Datum, Uhrzeit und Timestamp des Zeit-Objektes
   *
   * @param {*} date : Date
   */
  setFromObject(date) {
    if (date instanceof Date) {
      this.datum =
        date.getFullYear() +
        '-' +
        this.addNull(date.getMonth() + 1) +
        '-' +
        this.addNull(date.getDate())
      this.uhrzeit =
        this.addNull(date.getHours()) +
        ':' +
        this.addNull(date.getMinutes()) +
        ':' +
        this.addNull(date.getSeconds())
      this.timestamp = date.getTime()
    }
    return this
  }

  /**
   * Ändert das Datum und Timestamp des Zeit-Objektes
   *
   * @param {*} datum : String
   * null => Aktuelles Datum wird gespeichert.
   * JJJJ-MM-TT => Neues Datum wird gespeichert.
   */
  setDatum(datum = '') {
    var date = new Date()
    if (datum) {
      var datestrings = datum.split('-')
      if (datestrings.length == 3) {
        this.datum = datum
      }
    } else {
      this.datum =
        date.getFullYear() +
        '-' +
        this.addNull(date.getMonth() + 1) +
        '-' +
        this.addNull(date.getDate())
    }
    if (this.datum && this.uhrzeit) {
      this.generateTimestamp()
    }
    return this
  }

  /**
   * Ändert das Datum und Timestamp des Zeit-Objektes
   *
   * @param {*} datum : String
   * null => Aktuelles Datum wird gespeichert.
   * JJJJ-MM-TT => Neues Datum wird gespeichert.
   */
  setDatumUhrzeit(datum = '', uhrzeit = '') {
    var date = new Date()
    if (datum) {
      var datestrings = datum.split('-')
      if (datestrings.length == 3) {
        this.datum = datum
      }
    } else {
      this.datum =
        date.getFullYear() +
        '-' +
        this.addNull(date.getMonth() + 1) +
        '-' +
        this.addNull(date.getDate())
    }
    if (uhrzeit) {
      var time = uhrzeit.split(':')
      if (time.length == 2) {
        this.uhrzeit = uhrzeit + ':00'
      } else if (time.length == 1) {
        this.uhrzeit = uhrzeit + ':00:00'
      } else {
        this.uhrzeit = uhrzeit
      }
    } else {
      date = new Date()
      this.uhrzeit =
        this.addNull(date.getHours()) +
        ':' +
        this.addNull(date.getMinutes()) +
        ':' +
        this.addNull(date.getSeconds())
    }
    //setTimeout(() => {
    if (this.datum && this.uhrzeit) {
      this.generateTimestamp()
    } else {
      this.setDatumUhrzeit(datum, uhrzeit)
    }
    //}, 50)

    return this
  }

  /**
   * Ändert die Uhrzeit des Zeit-Objektes.
   *
   * @param {*} uhrzeit : String
   * null => Aktuelle Uhrzeit wird gespeichert.
   * hh:mm => Konvertierung in hh:mm:ss und Speicherung.
   * hh:mm:ss => Neue Uhrzeit wird gespeichert.
   */
  setUhrzeit(uhrzeit = '') {
    if (uhrzeit) {
      var time = uhrzeit.split(':')
      if (time.length == 2) {
        this.uhrzeit = uhrzeit + ':00'
      } else if (time.length == 1) {
        this.uhrzeit = uhrzeit + ':00:00'
      } else {
        this.uhrzeit = uhrzeit
      }
    } else {
      var date = new Date()
      this.uhrzeit =
        this.addNull(date.getHours()) +
        ':' +
        this.addNull(date.getMinutes()) +
        ':' +
        this.addNull(date.getSeconds())
    }
    if (this.datum && this.uhrzeit) {
      this.generateTimestamp()
    }
    return this
  }

  /**
   * Generiert aktuellen Timestamp (Milisekunden seit 1970) auf Basis des Datums und der Uhrzeit
   *
   * @returns Zeit
   */
  generateTimestamp() {
    if (!this.datum) {
      this.setDatum()
    }
    if (!this.uhrzeit) {
      this.setUhrzeit()
    }
    var date = this.datum.split('-')
    this.timestamp = Date.parse(
      date[2] +
        ' ' +
        this.translateMonth(date[1], 'RFC2822') +
        ' ' +
        date[0] +
        ' ' +
        this.uhrzeit +
        ' GMT+0200',
    )
    return this
  }

  /**
   * Gibt die Uhrzeit des Zeit-Objektes nach einem Fest definierten Format
   *
   * @returns String
   */
  getUhrzeit(format = '', delimiter = ':') {
    if (!format) {
      return this.uhrzeit
    } else {
      var zeitstr = ''
      var zeit = this.uhrzeit.split(':')
      var formatstr = format.split(delimiter)
      var i = 1
      formatstr.forEach((f) => {
        switch (f) {
          case 'H':
            zeitstr += this.removeNull(zeit[0])
            break
          case 'h':
            zeitstr += this.removeNull(zeit[0])
            break
          case 'hh':
            zeitstr += zeit[0]
            break
          case 'HH':
            zeitstr += zeit[0]
            break
          case 'M':
            zeitstr += this.removeNull(zeit[1])
            break
          case 'm':
            zeitstr += this.removeNull(zeit[1])
            break
          case 'mm':
            zeitstr += zeit[1]
            break
          case 'MM':
            zeitstr += zeit[1]
            break
          case 'S':
            zeitstr += this.removeNull(zeit[2])
            break
          case 's':
            zeitstr += this.removeNull(zeit[2])
            break
          case 'SS':
            zeitstr += zeit[2]
            break
          case 'ss':
            zeitstr += zeit[2]
            break
          default:
            zeitstr += f
            break
        }
        if (formatstr.length > i) {
          zeitstr += delimiter
        }
        i++
      })
      if (delimiter != ' ') {
        i = 1
        formatstr = format.split(' ')

        formatstr.forEach((f) => {
          if (i == 1) {
            i++
            return
          }
          zeitstr += ' ' + f
        })
      }
      return zeitstr
    }
  }

  /**
   * Gibt das Datum des Zeit-Objektes zurück.
   *
   * @returns String (Format JJJJ-MM-TT)
   */
  getDatum() {
    return this.datum
  }

  /**
   * Gibt den Timestamp des Zeit-Objektes zurück.
   * @returns String
   */
  getTimestamp() {
    return this.timestamp
  }

  /**
   * Gibt das aktuelle Zeit-Objekt zurück
   *
   * @returns Zeit
   */
  getZeit() {
    return this
  }

  /**
   * Prüft, ob Parameter < 10 und fügt bei Bedarf eine führende "0" hinzu.
   *
   * @param {*} number
   * @returns String
   */
  static add_null(number) {
    if (number < 10) {
      return '0' + number
    } else {
      return number
    }
  }
  addNull(number) {
    if (number < 10) {
      return '0' + number
    } else {
      return number
    }
  }

  removeNull(number) {
    if (number instanceof String) {
      if (number.startsWith('0')) {
        return number.substring(1)
      }
    }
    return number
  }

  translateMonth(month, format) {
    if (format == 'RFC2822') {
      switch (month) {
        case '01':
          return 'Jan'
        case '02':
          return 'Feb'
        case '03':
          return 'Mar'
        case '04':
          return 'Apr'
        case '05':
          return 'May'
        case '06':
          return 'Jun'
        case '07':
          return 'Jul'
        case '08':
          return 'Aug'
        case '09':
          return 'Sep'
        case '10':
          return 'Oct'
        case '11':
          return 'Nov'
        case '12':
          return 'Dec'
      }
    } else {
      return month
    }
  }

  toJSON() {
    return {
      datum: this.datum ? this.datum : '',
      uhrzeit: this.uhrzeit ? this.uhrzeit : '',
      timestamp: this.timestamp ? this.timestamp : '',
    }
  }
}
