import Zeit from './zeit'

export default class person {
  /**
   * @var id:String
   * id der Anwesenheitsaufzeichnung.
   */
  id = ''

  /**
   * @var vereinId:String
   * VereinsId der Anwesenheitsaufzeichnung
   */
  vereinId = ''

  /**
   * @var vorname:String
   * Vorname der Person
   */
  vorname = ''

  /**
   * @var nachname:String
   * Nachname der Person
   */
  nachname = ''

  /**
   * @var adresse:String
   * Straße und Hausnummer
   */
  adresse = ''

  /**
   * @var plz:int(5)
   * Postleitzahl
   */
  plz = ''

  /**
   * @var ort:String
   * Stadt / Ort
   */
  ort = ''

  /**
   * @var nation:String
   * Nation
   */
  nation = ''

  /**
   * @var telefon:int
   * Telefonnummer
   */
  telefon = ''

  /**
   * @var email:String
   * E-Mail Adresse
   */
  email = ''

  /**
   * @var veranstaltung:Veranstaltung
   * Veranstaltung
   */
  veranstaltung = ''

  /**
   * @var zutritt:Zeit
   */
  zutritt = ''

  /**
   * @var verlassen:Zeit
   */
  verlassen = ''

  /**
   * @var aktivitaet:String
   */
  aktivitaet = 'Zuschauer'

  /**
   * @var parentId:String
   * parentId der Person
   */
  parentId = ''
  /**
   * @var weitere_personen:Array
   * Vor- und Nachnamen der weiteren Personen des gleichen Hausstandes
   */
  weitere_personen = []

  /**
   * @var childIds:Array
   * childIds der Person
   */
  childIds = []

  /**
   * @var sportstaette:Sportstaette
   * Sportstätte auf der die Person anwesend ist.
   */
  sportstaette = ''

  /**
   * @var user:Object
   */
  user = ''

  /**
   * @var besuchId:String
   */
  besuchId = ''
  
  /**
   * @var ggg:Object
   */
  ggg = ''

  constructor(
    id = '',
    vereinId = '',
    vorname = '',
    nachname = '',
    adresse = '',
    plz = '',
    ort = '',
    nation = '',
    telefon = '',
    email = '',
    zutritt = '',
    verlassen = '',
    veranstaltung = '',
    parentId = '',
    weitere_personen = [],
    sportstaette = '',
    aktivitaet = 'Zuschauer',
    user = '',
    verein = '',
    ggg = ''
  ) {
    if (id && vereinId) {
      this.id = id
      this.vereinId = vereinId
      this.user = user
      this.loadPerson()
    } else {
      this.vereinId = vereinId
      this.vorname = vorname.trim()
      this.nachname = nachname.trim()
      this.adresse = adresse.trim()
      this.plz = plz.trim()
      this.ort = ort.trim()
      this.nation = nation.trim()
      this.telefon = telefon.trim()
      this.email = email.trim()
      this.veranstaltung = veranstaltung
      this.aktivitaet = aktivitaet
      this.setZutritt(zutritt)
      this.setVerlassen(verlassen)
      this.setSportstaette(sportstaette)
      this.parentId = parentId
      this.user = user
      this.verein = verein
      this.weitere_personen = weitere_personen
      this.ggg = ggg
    }
  }

  loadPerson(liveupdate = false) {
    if (liveupdate) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.vereinId)
        .collection('Anwesenheit')
        .doc(this.id)
        .onSnapshot((snap) => {
          var daten = snap.data()
          this.vorname = daten.person.vorname
          this.nachname = daten.person.name
          this.adresse = daten.person.adresse
          this.plz = daten.person.plz
          this.ort = daten.person.ort
          this.nation = daten.person.nation
          this.telefon = daten.person.telefon
          this.setZutritt(daten.zutritt)
          this.setVerlassen(daten.verlassen)
          this.setVeranstaltung(daten.veranstaltung)
          this.setSportstaette(daten.sportstaette)
          this.parentId = daten.parentId
          this.childIds = daten.childIds ? daten.childIds : []
          this.aktivitaet = daten.aktivitaet
        })
    } else {
      firebase
        .firestore()
        .collection('User')
        .doc(this.vereinId)
        .collection('Anwesenheit')
        .doc(this.id)
        .get()
        .then((doc) => {
          var daten = doc.data()
          this.vorname = daten.person.vorname
          this.nachname = daten.person.name
          this.adresse = daten.person.adresse
          this.plz = daten.person.plz
          this.ort = daten.person.ort
          this.nation = daten.person.nation
          this.telefon = daten.person.telefon
          this.aktivitaet = daten.aktivitaet
          this.setZutritt(daten.zutritt)
          this.setVerlassen(daten.verlassen)
          this.setVeranstaltung(daten.veranstaltung)
          this.setSportstaette(daten.sportstaette)
          this.parentId = daten.parentId
          this.childIds = daten.childIds ? daten.childIds : []
        })
    }
  }

  setVorname(vorname) {
    this.vorname = vorname.trim()
  }

  setNachname(nachname) {
    this.nachname = nachname.trim()
  }

  setAdresse(adresse) {
    this.adresse = adresse.trim()
  }

  setPLZ(plz) {
    this.plz = plz.trim()
  }

  setOrt(ort) {
    this.ort = ort.trim()
  }

  setNation(nation) {
    this.nation = nation.trim()
  }

  setTelefon(telefon) {
    this.telefon = telefon.trim()
  }

  setVeranstaltung(veranstaltung) {
    this.veranstaltung = veranstaltung
  }

  setZutritt(zutritt) {
    if (zutritt instanceof Zeit) {
      this.zutritt = zutritt
    } else if (zutritt instanceof Object) {
      this.zutritt = new Zeit(zutritt.datum, zutritt.uhrzeit)
    }
  }

  setVerlassen(verlassen) {
    if (verlassen instanceof Zeit) {
      this.verlassen = verlassen
    } else if (verlassen instanceof Object) {
      this.verlassen = new Zeit(verlassen.datum, verlassen.uhrzeit)
    }
  }

  setSportstaette(sportstaette) {
    if (sportstaette instanceof Sportstaette) {
      this.sportstaette = sportstaette
    } else if (sportstaette) {
      if (sportstaette.name) {
        this.sportstaette = new Sportstaette(
          sportstaette.name,
          sportstaette.link,
          sportstaette.main,
        )
      }
    }
  }

  setVerein(verein) {
    if (verein instanceof Object) {
      this.verein = verein
    } else if (this.vereinId) {
      firebase
        .firestore()
        .collection('User')
        .doc(vereinId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.verein = doc.data()
            this.verein.id = doc.id
          }
        })
    }
  }

  toJSON() {
    return {
      vorname: this.vorname ? this.vorname : '',
      name: this.nachname ? this.nachname : '',
      adresse: this.adresse ? this.adresse : '',
      plz: this.plz ? this.plz : '',
      ort: this.ort ? this.ort : '',
      nation: this.nation ? this.nation : '',
      telefon: this.telefon ? this.telefon : '',
      email: this.email ? this.email : '',
    }
  }

  getVeranstaltung() {
    return this.veranstaltung
  }

  getZutritt() {
    return this.zutritt
  }

  getVerlassen() {
    return this.verlassen
  }

  getSportstaette() {
    return this.sportstaette
  }

  getParentId() {
    return this.parentId
  }

  getChildIds() {
    return this.childIds
  }

  getUser() {
    return this.user
  }

  getVerein() {
    return this.verein
  }

  async check_in() {
    if (this.verein && this.zutritt && this.verlassen) {
      var anwesenheit = await this.check_anwesenheit()
      if (anwesenheit != null) {
        return {
          error: true,
          errortext:
            'Es ist bereits ein Zuschauer mit diesen Daten für die Veranstaltung eingecheckt.',
          person: anwesenheit,
        }
      } else {
        return firebase
          .firestore()
          .collection('User')
          .doc(this.vereinId)
          .collection('Anwesenheit')
          .add({
            person: this.toJSON(),
            veranstaltung: this.veranstaltung,
            zutritt: this.zutritt.toJSON(),
            verlassen: this.verlassen.toJSON(),
            weitere_personen: this.weitere_personen,
            aktivitaet: this.aktivitaet,
            sportstaette: this.sportstaette ? this.sportstaette.toJSON() : '',
            ggg: this.ggg ? this.ggg : ''
          })
          .then(async (docRef) => {
            console.log('Anwesenheit created')
            console.log(docRef.id)
            this.id = docRef.id
            if (this.user) {
              console.log('Try to create Besuch in User')
              return firebase
                .firestore()
                .collection('User')
                .doc(this.user.uid)
                .collection('Besuche')
                .add({
                  person: this.toJSON(),
                  anwesenheit: {
                    verein: this.vereinId,
                    id: docRef.id,
                  },
                  verein: this.verein.toJSON(),
                  veranstaltung: this.veranstaltung,
                  sportstaette: this.sportstaette ? this.sportstaette.toJSON() : '',
                  weitere_personen: this.weitere_personen,
                  zutritt: this.zutritt.toJSON(),
                  verlassen: this.verlassen.toJSON(),
                  aktivitaet: this.aktivitaet,
                })
                .then(async (besuchRef) => {
                  console.log('Besuch created!')
                  console.log(besuchRef.id)
                  this.besuchId = besuchRef.id
                  return firebase
                    .firestore()
                    .collection('User')
                    .doc(this.vereinId)
                    .collection('Anwesenheit')
                    .doc(docRef.id)
                    .update({
                      user: {
                        id: this.user.uid,
                        anwesenheit: besuchRef.id,
                      },
                    })
                    .then(async () => {
                      console.log('Anwesenheit updatetd! ' + besuchRef.id)
                      return {
                        success: true,
                        person: this,
                      }
                    })
                    .catch((error) => {
                      return {
                        error: true,
                        errortext: error,
                      }
                    })
                })
                .catch((error) => {
                  return {
                    error: true,
                    errortext: error,
                  }
                })
            } else {
              console.log('No User!')
              return {
                success: true,
                person: this,
              }
            }
          })
          .catch((error) => {
            console.log(error)
            return {
              error: true,
              errortext: error,
            }
          })
      }
    }
  }

  async check_anwesenheit() {
    var exists = ''
    await firebase
      .firestore()
      .collection('User')
      .doc(this.vereinId)
      .collection('Anwesenheit')
      .where('person.vorname', '==', this.vorname)
      .where('person.name', '==', this.nachname)
      .where('person.adresse', '==', this.adresse)
      .where('person.plz', '==', this.plz)
      .where('veranstaltung.id', '==', this.veranstaltung.id)
      .where('zutritt.datum', '==', this.zutritt.getDatum())
      .get()
      .then((snap) => {
        if (!snap.empty) {
          snap.forEach((doc) => {
            var anw = doc.data()
            console.log(anw)
            if (
              anw.verlassen.timestamp > this.zutritt.getTimestamp() &&
              (this.sportstaette ? anw.sportstaette.link == this.sportstaette.getLink() : true)
            ) {
              exists = doc.id
            }
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
    if (exists == true) {
      return new Person(exists, this.vereinId)
    } else {
      return null
    }
  }
}
