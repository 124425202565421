import Vue from 'vue'
import Vuex from 'vuex'
//import firebase from './firebaseConfig'
import router from './routes/index'
import style from './styles/template'
import { v4 as uuidv4 } from 'uuid'
import Zeit from './classes/zeit'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    template: style.getTemplate(),
    user: {
      loggedIn: false,
      loader: true,
      loggedOut: false,
      data: null,
      ga: {
        anfragen: {
          open: [],
          closed: [],
        },
      },
    },
    veranstaltung: {
      data: '',
      id: '',
      unsubscribe: '',
      anwesenheit: [],
      gastteam: [],
      anwesenheitsubscr: '',
      loader: '',
    },
    team: {
      data: '',
      id: '',
      unsubscribe: '',
    },
    filter: {
      veranstaltungen: {
        abteilung: 'Alle Abteilungen',
        sportstaette: 'Alle Sportstätten',
        team: 'Alle Teams',
        art: 'Alle Arten',
      },
    },

    publicdata: {
      verein: '',
      wettkampf: '',
      veranstaltungen: [],
      veranstaltung: '',
      zuschauer: '',
      loading: true,
    },
    wettkampfteam: [],
    verein: '',
    gastveranstaltung: '',
    gastteam: [],
    gast_team: [],
    timer: {
      datum: '',
      jahr: '',
      monat: '',
      tag: '',
      datum_format: '',
      stunden: '',
      minuten: '',
      sekunden: '',
      uhrzeit: '',
      uhrzeit_format_sec: '',
    },
  },
  getters: {
    filter(state) {
      return state.filter
    },
    template(state) {
      return state.template
    },
    user(state) {
      return state.user
    },
    veranstaltung(state) {
      return state.veranstaltung
    },
    team(state) {
      return state.team
    },
    timer(state) {
      return state.timer
    },
    publicdata(state) {
      return state.publicdata
    },
    verein(state) {
      return state.verein
    },
    gastveranstaltung(state) {
      return state.gastveranstaltung
    },
    gastteam(state) {
      return state.gastteam
    },
    gast_team(state) {
      return state.gast_team
    },
    wettkampfteam(state) {
      return state.wettkampfteam
    },
  },
  mutations: {
    SET_FILTER_VERANSTALTUNGEN(state, values) {
      state.filter.veranstaltungen = values
    },
    SET_TIMER(state) {
      var now = new Date()

      state.timer.jahr = now.getFullYear()
      state.timer.monat = parseInt(now.getMonth() + 1)
        .toString()
        .padStart(2, '0')
      state.timer.tag = now.getDate().toString().padStart(2, '0')
      state.timer.stunden = now.getHours().toString().padStart(2, '0')
      state.timer.minuten = now.getMinutes().toString().padStart(2, '0')
      state.timer.sekunden = now.getSeconds().toString().padStart(2, '0')

      state.timer.datum =
        state.timer.jahr + '-' + state.timer.monat + '-' + state.timer.tag
      state.timer.datum_format =
        state.timer.tag + '.' + state.timer.monat + '.' + state.timer.jahr
      state.timer.uhrzeit = state.timer.stunden + ':' + state.timer.minuten
      state.timer.uhrzeit_format_sec =
        state.timer.stunden +
        ':' +
        state.timer.minuten +
        ':' +
        state.timer.sekunden
    },
    SET_TEMPLATE_BG_GRAD(state, values) {
      state.template.background.grad_start = values.start
      state.template.background.grad_end = values.end
    },
    CHANGE_TEMPLATE(state, template) {
      if (template == 'Blau') {
        state.template = {
          colors: {
            primary: '#1565C0',
            inline_primary_text: '#FFFFFF',
            secondary: '#BBDEFB',
            inline_secondary_text: '#111111',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#1565C0',
            secondary: '#BBDEFB',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else if (template == 'Dunkelgrün') {
        state.template = {
          colors: {
            primary: '#2E7D32',
            inline_primary_text: '#FFFFFF',
            secondary: '#C8E6C9',
            inline_secondary_text: '#111111',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#2E7D32',
            secondary: '#C8E6C9',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else if (template == 'Gelb') {
        state.template = {
          colors: {
            primary: '#FBC02D',
            inline_primary_text: '#000000',
            secondary: '#FFF9C4',
            inline_secondary_text: '#111111',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#FBC02D',
            secondary: '#FFF9C4',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else if (template == 'Hellgrün') {
        state.template = {
          light: true,
          colors: {
            primary: '#64DD17',
            inline_primary_text: '#000000',
            secondary: '#CCFF90',
            inline_secondary_text: '#111111',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#64DD17',
            secondary: '#CCFF90',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else if (template == 'Rot') {
        state.template = {
          colors: {
            primary: '#B71C1C',
            inline_primary_text: '#FFFFFF',
            secondary: '#FFCDD2',
            inline_secondary_text: '#111111',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#B71C1C',
            secondary: '#FFCDD2',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else if (template == 'Schwarz') {
        state.template = {
          colors: {
            primary: '#000000',
            inline_primary_text: '#FFFFFF',
            secondary: '#424242',
            inline_secondary_text: '#FFFFFF',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#000000',
            secondary: '#424242',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else if (template == 'Weiss') {
        state.template = {
          light: true,
          colors: {
            primary: '#FFFFFF',
            inline_primary_text: '#000000',
            secondary: '#BDBDBD',
            inline_secondary_text: '#000000',
            blocks: '#000000',
            block_text: '#FFFFFF',
          },
          background: {
            primary: '#FFFFFF',
            secondary: '#BDBDBD',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      } else {
        state.template = {
          colors: {
            primary: '#dc0c23',
            inline_primary_text: '#FFFFFF',
            secondary: '#dddddd',
            inline_secondary_text: '#111111',
            blocks: '#FFFFFF',
            block_text: '#000000',
          },
          background: {
            primary: '#dc0c23',
            secondary: '#dddddd',
            grad_start: '28%',
            grad_end: '100%',
          },
        }
      }
    },
    SET_WETTKAMPF(state, value) {
      state.publicdata.nodata = true
      state.publicdata.wettkampf = ''
      state.publicdata.loading = true
      firebase
        .firestore()
        .collection('User')
        .where('alias', '==', value.name)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            if (doc.exists) {
              state.publicdata.verein = doc.data()
              state.publicdata.verein.id = doc.id
              if (state.publicdata.verein) {
                state.publicdata.nodata = false
              }
              firebase
                .firestore()
                .collection('User')
                .doc(doc.id)
                .collection('Veranstaltung')
                .doc(value.veranstaltung)
                .get()
                .then(
                  (ref) => {
                    state.publicdata.wettkampf = ref.data()
                    state.publicdata.wettkampf.id = ref.id
                    state.publicdata.wettkampf.nation_required = ref.data()
                      .nation_required
                      ? ref.data().nation_required
                      : false
                    firebase
                      .firestore()
                      .collection('User')
                      .doc(doc.id)
                      .collection('Veranstaltung')
                      .doc(value.veranstaltung)
                      .collection('Disziplin')
                      .where('public', '==', true)
                      .get()
                      .then((snap) => {
                        state.publicdata.wettkampf.disziplinen = []
                        var i = 0
                        snap.forEach((disz) => {
                          state.publicdata.wettkampf.disziplinen.push(
                            disz.data(),
                          )
                          state.publicdata.wettkampf.disziplinen[i].id = disz.id
                          i++
                        })
                        state.publicdata.loading = false
                      })
                  },
                  (error) => {
                    console.log(error)
                    state.publicdata.loading = false
                  },
                )
            } else {
              state.publicdata.loading = false
            }
          })
        })
    },
    SET_PUBLIC(state, value) {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      var yyyy = today.getFullYear()

      today = yyyy + '-' + mm + '-' + dd
      var current_time = new Date()
      current_time.setHours(current_time.getHours())
      var time =
        (current_time.getHours() < 10
          ? '0' + current_time.getHours()
          : current_time.getHours()) +
        ':' +
        (current_time.getMinutes() < 10
          ? '0' + current_time.getMinutes()
          : current_time.getMinutes())

      state.publicdata.nodata = true
      state.publicdata.veranstaltungen = []
      firebase
        .firestore()
        .collection('User')
        .where('alias', '==', value.name)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            if (doc.exists) {
              state.publicdata.verein = doc.data()
              state.publicdata.verein.id = doc.id
              if (state.publicdata.verein) {
                state.publicdata.nodata = false
              }

              if (
                state.publicdata.verein.verband != state.template.var.verband
              ) {
                window.location.replace(
                  state.template.getVerband(state.publicdata.verein.verband)
                    .url +
                    '/' +
                    state.publicdata.verein.alias,
                )
              }

              if (state.publicdata.verein.sportstaetten) {
                let sportstaetten = state.publicdata.verein.sportstaetten
                if (typeof value.sportstaette !== 'undefined') {
                  let search = value.sportstaette
                  for (let i = 0; i < sportstaetten.length; i++) {
                    if (sportstaetten[i].link == search) {
                      state.publicdata.sportstaette = sportstaetten[i]
                    }
                  }
                } else {
                  state.publicdata.sportstaette = sportstaetten[0]
                }
              } else {
                state.publicdata.sportstaette = ''
              }

              //Generate Dates
              var generatedDates = Zeit.generateDays(7, 0)
              var tage = generatedDates.tage

              if (typeof value.sportstaette !== 'undefined') {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(doc.id)
                  .collection('Veranstaltung')
                  .where('beginn.datum', 'in', tage)
                  .where('art', '!=', 'Auswärtsspiel')
                  .onSnapshot((ref) => {
                    state.publicdata.veranstaltungen = []
                    ref.forEach((v) => {
                      var event = v.data()
                      if (
                        event.beginn.datum == today ||
                        event.ende.datum == today ||
                        (event.beginn.datum < today && event.ende.datum > today)
                      ) {
                        if (
                          v.data().sportstaette ==
                          state.publicdata.sportstaette.name
                        ) {
                          if (
                            (v.data().ende.datum > today ||
                              (v.data().ende.datum == today &&
                                v.data().ende.uhrzeit > time)) &&
                            !v.data().abgesagt
                          ) {
                            state.publicdata.veranstaltungen.push(v.data())
                            var index =
                              state.publicdata.veranstaltungen.length - 1
                            state.publicdata.veranstaltungen[index].id = v.id
                          }
                        } else if (v.data().art == 'Wettkampf') {
                          firebase
                            .firestore()
                            .collection('User')
                            .doc(doc.id)
                            .collection('Veranstaltung')
                            .doc(v.id)
                            .collection('Disziplin')
                            .where('beginn.datum', '==', today)
                            .where(
                              'sportstaette',
                              '==',
                              state.publicdata.sportstaette.name,
                            )
                            .get()
                            .then((disziplinen) => {
                              if (!disziplinen.empty) {
                                state.publicdata.veranstaltungen.push(v.data())
                                var index =
                                  state.publicdata.veranstaltungen.length - 1
                                state.publicdata.veranstaltungen[index].id =
                                  v.id
                              }
                            })
                        }
                      }
                    })
                  })
              } else if (state.publicdata.verein.sportstaetten) {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(doc.id)
                  .collection('Veranstaltung')
                  .where('beginn.datum', 'in', tage)
                  .where('art', '!=', 'Auswärtsspiel')
                  .onSnapshot((ref) => {
                    state.publicdata.veranstaltungen = []
                    ref.forEach((v) => {
                      var event = v.data()
                      if (
                        event.beginn.datum == today ||
                        event.ende.datum == today ||
                        (event.beginn.datum < today && event.ende.datum > today)
                      ) {
                        if (
                          !v.data().abgesagt &&
                          (v.data().sportstaette ==
                            state.publicdata.verein.sportstaetten[0].name ||
                            !v.data().sportstaette ||
                            v.data().sportstaette == ' ')
                        ) {
                          if (
                            v.data().ende.datum > today ||
                            (v.data().ende.datum == today &&
                              v.data().ende.uhrzeit > time)
                          ) {
                            state.publicdata.veranstaltungen.push(v.data())
                            var index =
                              state.publicdata.veranstaltungen.length - 1
                            state.publicdata.veranstaltungen[index].id = v.id
                          }
                        } else if (v.data().art == 'Wettkampf') {
                          firebase
                            .firestore()
                            .collection('User')
                            .doc(doc.id)
                            .collection('Veranstaltung')
                            .doc(v.id)
                            .collection('Disziplin')
                            .where('beginn.datum', '==', today)
                            .where(
                              'sportstaette',
                              '==',
                              state.publicdata.sportstaette.name,
                            )
                            .get()
                            .then((disziplinen) => {
                              if (!disziplinen.empty) {
                                state.publicdata.veranstaltungen.push(v.data())
                                var index =
                                  state.publicdata.veranstaltungen.length - 1
                                state.publicdata.veranstaltungen[index].id =
                                  v.id
                              }
                            })
                        }
                      }
                    })
                  })
              } else {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(doc.id)
                  .collection('Veranstaltung')
                  .where('beginn.datum', 'in', tage)
                  .where('art', '!=', 'Auswärtsspiel')
                  .onSnapshot((ref) => {
                    state.publicdata.veranstaltungen = []
                    ref.forEach((v) => {
                      var event = v.data()
                      if (
                        event.beginn.datum == today ||
                        event.ende.datum == today ||
                        (event.beginn.datum < today && event.ende.datum > today)
                      ) {
                        if (
                          !v.data().abgesagt &&
                          (!v.data().sportstaette ||
                            v.data().sportstaette == ' ')
                        ) {
                          if (
                            v.data().ende.datum > today ||
                            (v.data().ende.datum == today &&
                              v.data().ende.uhrzeit > time)
                          ) {
                            state.publicdata.veranstaltungen.push(v.data())
                            var index =
                              state.publicdata.veranstaltungen.length - 1
                            state.publicdata.veranstaltungen[index].id = v.id
                          }
                        }
                      }
                    })
                  })
              }
            }
          })
        })
    },
    SET_PUBLIC_VERANSTALTUNG(state, ids) {
      if (!state.publicdata.verein) {
        var today = new Date()
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        var yyyy = today.getFullYear()

        today = yyyy + '-' + mm + '-' + dd
        state.publicdata.veranstaltungen = []
        firebase
          .firestore()
          .collection('User')
          .where('alias', '==', ids.alias)
          .get()
          .then((docRef) => {
            docRef.forEach((doc) => {
              state.publicdata.verein = doc.data()
              state.publicdata.verein.id = doc.id
              if (!state.publicdata.verein) {
                state.publicdata.nodata = true
              }
              firebase
                .firestore()
                .collection('User')
                .doc(doc.id)
                .collection('Veranstaltung')
                .where('beginn.datum', '==', today)
                .get()
                .then((ref) => {
                  ref.forEach((v) => {
                    if (v.data().art != 'Auswärtsspiel' && !v.data().abgesagt) {
                      state.publicdata.veranstaltungen.push(v.data())
                      var index = state.publicdata.veranstaltungen.length - 1
                      state.publicdata.veranstaltungen[index].id = v.id
                    }
                  })
                })

              if (ids.veranstaltung != 'andere') {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(doc.id)
                  .collection('Veranstaltung')
                  .doc(ids.veranstaltung)
                  .get()
                  .then((docR) => {
                    state.publicdata.veranstaltung = docR.data()
                    state.publicdata.veranstaltung.id = docR.id
                  })
              } else {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(doc.id)
                  .collection('Anwesenheit')
                  .doc(ids.zuschauer)
                  .get()
                  .then((docR) => {
                    var daten = docR.data()
                    state.publicdata.veranstaltung = {
                      id: 'andere',
                      art: 'Andere',
                      beginn: {
                        datum: daten.datum,
                        uhrzeit: daten.zutritt,
                      },
                      ende: {
                        datum: daten.endedatum,
                        uhrzeit: daten.verlassen,
                      },
                      gastmannschaft: '',
                      mannschaft: '',
                      maxzuschauer: '',
                      name: '',
                    }
                  })
              }
            })
          })
      } else {
        if (ids.veranstaltung != 'andere') {
          firebase
            .firestore()
            .collection('User')
            .doc(state.publicdata.verein.id)
            .collection('Veranstaltung')
            .doc(ids.veranstaltung)
            .get()
            .then((doc) => {
              state.publicdata.veranstaltung = doc.data()
              state.publicdata.veranstaltung.id = doc.id
            })
        } else {
          firebase
            .firestore()
            .collection('User')
            .doc(state.publicdata.verein.id)
            .collection('Anwesenheit')
            .doc(ids.zuschauer)
            .get()
            .then((docR) => {
              var daten = docR.data()
              state.publicdata.veranstaltung = {
                id: 'andere',
                art: 'Andere',
                beginn: {
                  datum: daten.datum,
                  uhrzeit: daten.zutritt,
                },
                ende: {
                  datum: daten.endedatum,
                  uhrzeit: daten.verlassen,
                },
                gastmannschaft: '',
                mannschaft: '',
                maxzuschauer: '',
                name: '',
              }
            })
        }
      }
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_LOGGED_OUT(state, value) {
      if (state.user.snap) {
        state.user.snap()
      }
      if (state.user.zuschauersnap) {
        state.user.zuschauersnap()
      }
      if (state.user.vereinsnap) {
        state.user.vereinsnap()
      }
      if (state.veranstaltung) {
        if (state.veranstaltung.gastteamsubscr) {
          state.veranstaltung.gastteamsubscr.vereinsnap()
        }
        if (state.veranstaltung.anwesenheitsubscr) {
          state.veranstaltung.anwesenheitsubscr.vereinsnap()
        }
        if (state.veranstaltung.unsubscribe) {
          state.veranstaltung.unsubscribe.vereinsnap()
        }
      }
      state.user = {
        loggedIn: false,
        loader: true,
        loggedOut: true,
        data: null,
      }
    },
    SET_LOADING(state, value) {
      state.user.loader = value
    },
    SET_USER(state, data) {
      let doc, cdoc, vdoc
      if (data) {
        state.user.displayName = data.displayName
        state.user.photoUrl = data.photoUrl
        state.user.email = data.email
        state.user.uid = data.uid
        state.user.fullfill = data.fullfill
        if (data.provider) {
          state.user.provider = data.provider
        } else {
          state.user.provider = 'password'
        }

        if (data.userdata) {
          if (data.userdata.provider) {
            state.user.provider = data.userdata.provider
          }
          if (
            data.userdata.rolle == 'Trainer' ||
            data.userdata.rolle == 'Zuschauerkontrolle'
          ) {
            //Traineraccount
            doc = firebase.firestore().collection('User').doc(data.uid)
            state.user.snap = doc.onSnapshot((daten) => {
              if (daten.exists) {
                state.user.data = daten.data()
                state.user.data.uid = daten.id
                if (!Array.isArray(state.user.data.team)) {
                  var team = state.user.data.team
                  state.user.data.team = []
                  state.user.data.team.push(team)
                }
                firebase
                  .firestore()
                  .collection('User')
                  .doc(daten.data().verein.id)
                  .get()
                  .then((verein) => {
                    if (verein.exists) {
                      state.user.data.uid = verein.id
                      state.user.data.verein.id = verein.id
                      if (!verein.data().maxzuschauer) {
                        state.user.data.maxzuschauer = 300
                      } else {
                        state.user.data.maxzuschauer = verein.data().maxzuschauer
                      }
                      /**************************************************************
                      *******                                                 *******
                      *******                 EIGENES TEMPLATE                *******
                      *******                                                 *******
                      ***************************************************************
                      if (verein.data().verein.template) {
                        state.template = getTemplate.getTemplate(
                          verein.data().verein.template,
                        )
                      }*/
                      state.user.data.verein.name = verein.data().verein.name
                      state.user.data.verein.logo = verein.data().verein.logo
                      state.user.data.alias = verein.data().alias
                      state.user.data.sportstaetten = verein.data().sportstaetten
                      state.user.data.abteilungen = verein.data().abteilungen
                      if (!state.user.data.abteilungen) {
                        state.user.data.abteilungen = []
                      }
                      state.user.data.hygienekonzept = verein.data().hygienekonzept
                      state.user.data.aktivitaeten = daten.data().aktivitaeten
                      if (!state.user.data.aktivitaeten) {
                        state.user.data.aktivitaeten = []
                        state.user.data.aktivitaeten = state.user.data.aktivitaeten.concat(
                          style.getTemplate().var.standard_aktivitaeten,
                        )
                      }
                    }
                  })
              }
            })
            vdoc = firebase
              .firestore()
              .collection('User')
              .doc(data.userdata.verein.id)
            state.user.vereinsnap = vdoc.onSnapshot((daten) => {
              if (daten.exists) {
                state.user.data.uid = daten.id
                state.user.data.verein.id = daten.id
                if (!daten.data().maxzuschauer) {
                  state.user.data.maxzuschauer = 300
                }
                /**************************************************************
                      *******                                                 *******
                      *******                 EIGENES TEMPLATE                *******
                      *******                                                 *******
                      ***************************************************************
                if (daten.data().verein.template) {
                  state.template = getTemplate.getTemplate(
                    daten.data().verein.template,
                  )
                }*/
                state.user.data.verein.name = daten.data().verein.name
                state.user.data.verein.logo = daten.data().verein.logo
                state.user.data.alias = daten.data().alias
                state.user.data.sportstaetten = daten.data().sportstaetten
                state.user.data.abteilungen = daten.data().abteilungen
                if (!state.user.data.abteilungen) {
                  state.user.data.abteilungen = []
                }
                state.user.data.aktivitaeten = daten.data().aktivitaeten
                if (!state.user.data.aktivitaeten) {
                  state.user.data.aktivitaeten = []
                  state.user.data.aktivitaeten = state.user.data.aktivitaeten.concat(
                    style.getTemplate().var.standard_aktivitaeten,
                  )
                }
              }
            })
            cdoc = firebase
              .firestore()
              .collection('User')
              .doc(data.uid)
              .collection('Kontaktdaten')
              .doc(data.uid)
            state.user.zuschauersnap = cdoc.onSnapshot((kdaten) => {
              if (kdaten.exists) {
                state.user.zuschauerdaten = kdaten.data()
                if (!kdaten.data().nation) {
                  state.user.zuschauerdaten.nation = 'Deutschland'
                }
              } else {
                state.user.zuschauerdaten = ''
              }
            })
          } else if (data.userdata.rolle == 'Vorstand') {
            if (data.userdata.verein.id) {
              //Zusätzlicher Vorstand ==> Daten vom Hauptaccount laden
              doc = firebase
                .firestore()
                .collection('User')
                .doc(data.userdata.verein.id)
              state.user.snap = doc.onSnapshot((daten) => {
                if (daten.exists) {
                  state.user.data = daten.data()
                  state.user.data.uid = daten.id
                  if (!daten.data().maxzuschauer) {
                    state.user.data.maxzuschauer = 300
                  }
                  /**************************************************************
                      *******                                                 *******
                      *******                 EIGENES TEMPLATE                *******
                      *******                                                 *******
                      ***************************************************************
                  if (daten.data().verein.template) {
                    state.template = getTemplate.getTemplate(
                      daten.data().verein.template,
                    )
                  }*/
                  if (!state.user.data.abteilungen) {
                    state.user.data.abteilungen = []
                  }
                  if (!state.user.data.aktivitaeten) {
                    state.user.data.aktivitaeten = []
                    state.user.data.aktivitaeten = state.user.data.aktivitaeten.concat(
                      style.getTemplate().var.standard_aktivitaeten,
                    )
                  }
                }
              })
              firebase
                .firestore()
                .collection('User')
                .doc(data.userdata.verein.id)
                .collection('Ereignisse')
                .onSnapshot((snap) => {
                  snap.forEach((daten) => {
                    const ereignis = daten.data()
                    if (
                      ereignis.status ? ereignis.status == 'SUCCESS' : false
                    ) {
                      state.user.ga.anfragen.closed.push(ereignis)
                    } else {
                      state.user.ga.anfragen.open.push(ereignis)
                    }
                  })
                })
              cdoc = firebase
                .firestore()
                .collection('User')
                .doc(data.uid)
                .collection('Kontaktdaten')
                .doc(data.uid)
              state.user.zuschauersnap = cdoc.onSnapshot((kdaten) => {
                if (kdaten.exists) {
                  state.user.zuschauerdaten = kdaten.data()
                  if (!kdaten.data().nation) {
                    state.user.zuschauerdaten.nation = 'Deutschland'
                  }
                } else {
                  state.user.zuschauerdaten = ''
                }
              })
            } else {
              //Hauptaccount
              doc = firebase.firestore().collection('User').doc(data.uid)
              state.user.snap = doc.onSnapshot((daten) => {
                if (daten.exists) {
                  state.user.data = daten.data()
                  state.user.data.uid = daten.id
                  if (!daten.data().maxzuschauer) {
                    state.user.data.maxzuschauer = 300
                  }
                  /**************************************************************
                      *******                                                 *******
                      *******                 EIGENES TEMPLATE                *******
                      *******                                                 *******
                      ***************************************************************
                  if (daten.data().verein.template) {
                    state.template = getTemplate.getTemplate(
                      daten.data().verein.template,
                    )
                  }*/
                  if (!state.user.data.abteilungen) {
                    state.user.data.abteilungen = []
                  }
                  if (!state.user.data.aktivitaeten) {
                    state.user.data.aktivitaeten = []
                    state.user.data.aktivitaeten = state.user.data.aktivitaeten.concat(
                      style.getTemplate().var.standard_aktivitaeten,
                    )
                  }
                }
              })
              firebase
                .firestore()
                .collection('User')
                .doc(data.uid)
                .collection('Ereignisse')
                .onSnapshot((snap) => {
                  state.user.ga.anfragen.closed = []
                  state.user.ga.anfragen.open = []
                  snap.forEach((daten) => {
                    const ereignis = daten.data()
                    if (
                      ereignis.status ? ereignis.status == 'SUCCESS' : false
                    ) {
                      state.user.ga.anfragen.closed.push(ereignis)
                    } else {
                      state.user.ga.anfragen.open.push(ereignis)
                    }
                  })
                })
              cdoc = firebase
                .firestore()
                .collection('User')
                .doc(data.uid)
                .collection('Kontaktdaten')
                .doc(data.uid)
              state.user.zuschauersnap = cdoc.onSnapshot((kdaten) => {
                if (kdaten.exists) {
                  state.user.zuschauerdaten = kdaten.data()
                  if (!kdaten.data().nation) {
                    state.user.zuschauerdaten.nation = 'Deutschland'
                  }
                } else {
                  state.user.zuschauerdaten = ''
                }
              })
            }
          } else {
            //Zuschaueraccount
            doc = firebase.firestore().collection('User').doc(data.uid)
            state.user.snap = doc.onSnapshot((daten) => {
              if (daten.exists) {
                state.user.data = daten.data()
                state.user.data.uid = daten.id
              }
            })
            cdoc = firebase
              .firestore()
              .collection('User')
              .doc(data.uid)
              .collection('Kontaktdaten')
              .doc(data.uid)
            state.user.zuschauersnap = cdoc.onSnapshot((kdaten) => {
              if (kdaten.exists) {
                state.user.zuschauerdaten = kdaten.data()
                if (!kdaten.data().nation) {
                  state.user.zuschauerdaten.nation = 'Deutschland'
                }
              }
            })
            if (
              router.currentRoute.fullPath == '/login' &&
              !state.user.fullfill
            ) {
              router.push('/user/check-ins')
            } else if (state.user.fullfill) {
              router.push('/profil-vervollstaendigen')
            }
          }
        } else {
          //Zuschaueraccount
          doc = firebase.firestore().collection('User').doc(data.uid)
          state.user.snap = doc.onSnapshot((daten) => {
            if (daten.exists) {
              state.user.data = daten.data()
              state.user.data.uid = daten.id
            }
          })
          cdoc = firebase
            .firestore()
            .collection('User')
            .doc(data.uid)
            .collection('Kontaktdaten')
            .doc(data.uid)
          state.user.zuschauersnap = cdoc.onSnapshot((kdaten) => {
            if (kdaten.exists) {
              state.user.zuschauerdaten = kdaten.data()
              if (!kdaten.data().nation) {
                state.user.zuschauerdaten.nation = 'Deutschland'
              }
            }
          })
          if (
            router.currentRoute.fullPath == '/login' &&
            !state.user.fullfill
          ) {
            router.push('/user/check-ins')
          } else if (state.user.fullfill) {
            router.push('/profil-vervollstaendigen')
          }
        }
      }
      setTimeout(() => {
        state.user.loader = false
      }, 1000)
    },
    async ADD_GASTTEAM_MEMBER(state, member) {
      await firebase
        .firestore()
        .collection('User')
        .doc(state.verein.id)
        .collection('Anwesenheit')
        .add(member)
        .then((doc) => {
          member.id = doc.id
          firebase
            .firestore()
            .collection('User')
            .doc(state.verein.id)
            .collection('Anwesenheit')
            .doc(member.id)
            .update({
              'verein.spieler': member.id,
            })
            .then(() => {
              state.gastteam.push(member)
            })
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
        })
    },
    async REMOVE_GASTTEAM_MEMBER(state, member) {
      await firebase
        .firestore()
        .collection('User')
        .doc(state.verein.id)
        .collection('Anwesenheit')
        .doc(member.id)
        .delete()
        .then((doc) => {
          var i
          for (i = 0; i < state.gastteam.length; i++) {
            if (state.gastteam[i].id == member.id) {
              state.gastteam.splice(i, 1)
            }
          }
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
        })
    },
    async ADD_WETTKAMPF_TEAM_MEMBER(state, member) {
      member.id = uuidv4()
      state.wettkampfteam.push(member)
    },
    async REMOVE_WETTKAMPF_TEAM_MEMBER(state, member) {
      var i
      for (i = 0; i < state.wettkampfteam.length; i++) {
        if (state.wettkampfteam[i].id == member.id) {
          state.wettkampfteam.splice(i, 1)
        }
      }
    },
    LOAD_GAST_TEAM(state, team) {
      firebase
        .firestore()
        .collection('User')
        .doc(state.user.data.uid)
        .collection('Team')
        .doc(team)
        .collection('Mitglieder')
        .get()
        .then((snap) => {
          state.gast_team = []
          snap.forEach((doc) => {
            state.gast_team.push(doc.data())
            var i, index
            index = state.gast_team.length - 1
            state.gast_team[index].id = doc.id
            for (i = 0; i < state.gastteam.length; i++) {
              if (state.gastteam[i].id) {
                if (state.gastteam[i].id == state.gast_team[index].id) {
                  state.gast_team[index].anwesend = true
                }
              }
            }
          })
        })
    },
    LOAD_GASTTEAM(state) {
      firebase
        .firestore()
        .collection('User')
        .doc(state.verein.id)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', state.gastveranstaltung.id)
        .where('verein.gastverein', '==', true)
        .get()
        .then((snap) => {
          var index
          state.gastteam = []
          snap.forEach((doc) => {
            state.gastteam.push(doc.data())
            index = state.gastteam.length - 1
            state.gastteam[index].id = doc.id
          })
        })
    },
    SET_GASTTEAM_SEITE(state, data) {
      state.verein = data.verein
      state.gastveranstaltung = data.veranstaltung
    },
    SET_USER_ANONYM(state) {
      state.user.anonym = true
      setTimeout(() => {
        state.user.loader = false
      }, 1000)
    },
    SET_VERANSTALTUNG(state, id) {
      var date_today = new Date()
      date_today.setDate(date_today.getDate() - 2)
      var date_today_month =
        date_today.getMonth() + 1 < 10
          ? '0' + parseInt(date_today.getMonth() + 1)
          : date_today.getMonth() + 1
      var date_today_day =
        date_today.getDate() < 10
          ? '0' + parseInt(date_today.getDate())
          : date_today.getDate()
      var today =
        date_today.getFullYear() + '-' + date_today_month + '-' + date_today_day

      state.veranstaltung.loader = true
      setTimeout(function () {
        state.veranstaltung.unsubscribe = firebase
          .firestore()
          .collection('User')
          .doc(state.user.data.uid)
          .collection('Veranstaltung')
          .doc(id)
          .onSnapshot((doc) => {
            state.veranstaltung.data = doc.data()
            state.veranstaltung.id = id
            state.veranstaltung.data.nation_required = state.veranstaltung.data
              .nation_required
              ? state.veranstaltung.data.nation_required
              : false
            if (
              state.veranstaltung.data.beginn.datum >= today ||
              state.veranstaltung.data.ende.datum >= today
            ) {
              state.veranstaltung.data.valid = today
            } else {
              state.veranstaltung.data.valid = false
            }
            if (state.user.data.sportstaetten) {
              if (!state.veranstaltung.data.sportstaette) {
                state.veranstaltung.data.sportstaette =
                  state.user.data.sportstaetten[0].name
                state.veranstaltung.data.sportstaettenobj =
                  state.user.data.sportstaetten[0]
                state.veranstaltung.data.sportstaettenobj.index = 0
                state.veranstaltung.data.sportstaettenobj.indexlink = ''
              } else {
                state.veranstaltung.data.sportstaettenobj = { indexlink: '' }
                for (let i = 0; i < state.user.data.sportstaetten.length; i++) {
                  if (
                    state.veranstaltung.data.sportstaette ==
                    state.user.data.sportstaetten[i].name
                  ) {
                    state.veranstaltung.data.sportstaettenobj =
                      state.user.data.sportstaetten[i]
                    state.veranstaltung.data.sportstaettenobj.index = i
                    if (i != 0) {
                      state.veranstaltung.data.sportstaettenobj.indexlink =
                        '/' + i
                    } else {
                      state.veranstaltung.data.sportstaettenobj.indexlink = ''
                    }
                  }
                }
              }
            } else {
              state.veranstaltung.data.sportstaettenobj = { indexlink: '' }
            }
            if (
              state.veranstaltung.data.art == 'Freundschaftsspiel' ||
              state.veranstaltung.data.art == 'Meisterschaftsspiel' ||
              state.veranstaltung.data.art == 'Pokalspiel'
            ) {
              state.veranstaltung.gastteamsubscr = firebase
                .firestore()
                .collection('User')
                .doc(state.user.data.uid)
                .collection('Veranstaltung')
                .doc(id)
                .collection('Gastteam')
                .orderBy('name')
                .onSnapshot((docRef) => {
                  state.veranstaltung.gastteam = []
                  docRef.forEach((doca) => {
                    var person = doca.data()
                    state.veranstaltung.gastteam.push(doca.data())
                    var len = state.veranstaltung.gastteam.length - 1
                    state.veranstaltung.gastteam[len].id = doca.id
                  })
                })
            } else {
              state.veranstaltung.gastteam = []
            }
            state.veranstaltung.loader = false
          })
        if (state.veranstaltung.data.gastgeberverein) {
          state.veranstaltung.anwesenheitsubscr = firebase
            .firestore()
            .collection('User')
            .doc(state.veranstaltung.gastgeberverein.id)
            .collection('Anwesenheit')
            .where(
              'veranstaltung.id',
              '==',
              state.veranstaltung.gastgeberverein.veranstaltung,
            )
            .where('verein.id', '==', state.user.data.uid)
            .orderBy('person.name')
            .onSnapshot((docRef) => {
              state.veranstaltung.anwesenheit = []
              docRef.forEach((doca) => {
                var person = doca.data()
                person.id = doca.id
                state.veranstaltung.anwesenheit.push(person)
                if (person.weitere_personen) {
                  if (person.weitere_personen.length > 0) {
                    var personen = person.weitere_personen
                    personen.forEach((p) => {
                      if (p.vorname) {
                        state.veranstaltung.anwesenheit.push(doca.data())
                        let len = state.veranstaltung.anwesenheit.length - 1
                        state.veranstaltung.anwesenheit[len].person.vorname =
                          p.vorname
                        state.veranstaltung.anwesenheit[len].person.name =
                          p.nachname
                      } else {
                        let name = p.split(' ')
                        state.veranstaltung.anwesenheit.push(doca.data())
                        let len = state.veranstaltung.anwesenheit.length - 1
                        state.veranstaltung.anwesenheit[len].person.vorname =
                          name[0]
                        state.veranstaltung.anwesenheit[len].person.name =
                          name[1]
                      }
                    })
                  }
                }
              })
            })
        } else {
          state.veranstaltung.anwesenheit = []
          if (state.veranstaltung.data.art == 'Wettkampf') {
            state.veranstaltung.anwesenheitsubscr = firebase
              .firestore()
              .collection('User')
              .doc(state.user.data.uid)
              .collection('Anwesenheit')
              .where('veranstaltung.id', '==', id)
              .orderBy('person.name')
              .onSnapshot((docRef) => {
                state.veranstaltung.anwesenheit = []
                docRef.forEach((doca) => {
                  var person = doca.data()
                  person.id = doca.id
                  state.veranstaltung.anwesenheit.push(person)
                  if (person.weitere_personen) {
                    if (person.weitere_personen.length > 0) {
                      var personen = person.weitere_personen
                      personen.forEach((p) => {
                        if (p.vorname) {
                          state.veranstaltung.anwesenheit.push(doca.data())
                          let len = state.veranstaltung.anwesenheit.length - 1
                          state.veranstaltung.anwesenheit[len].person.vorname =
                            p.vorname
                          state.veranstaltung.anwesenheit[len].person.name =
                            p.nachname
                        } else {
                          let name = p.split(' ')
                          state.veranstaltung.anwesenheit.push(doca.data())
                          let len = state.veranstaltung.anwesenheit.length - 1
                          state.veranstaltung.anwesenheit[len].person.vorname =
                            name[0]
                          state.veranstaltung.anwesenheit[len].person.name =
                            name[1]
                        }
                      })
                    }
                  }
                })
              })
          } else {
            state.veranstaltung.anwesenheitsubscr = firebase
              .firestore()
              .collection('User')
              .doc(state.user.data.uid)
              .collection('Anwesenheit')
              .where('veranstaltung.id', '==', id)
              .orderBy('person.name')
              .onSnapshot((docRef) => {
                state.veranstaltung.anwesenheit = []
                docRef.forEach((doca) => {
                  var person = doca.data()
                  person.id = doca.id
                  state.veranstaltung.anwesenheit.push(person)
                  if (person.weitere_personen) {
                    if (person.weitere_personen.length > 0) {
                      var personen = person.weitere_personen
                      personen.forEach((p) => {
                        if (typeof p == 'object') {
                          state.veranstaltung.anwesenheit.push(doca.data())
                          let len = state.veranstaltung.anwesenheit.length - 1
                          state.veranstaltung.anwesenheit[len].person.vorname =
                            p.vorname
                          state.veranstaltung.anwesenheit[len].person.name =
                            p.nachname
                        } else if (typeof p == 'string') {
                          let name = p.split(' ')
                          state.veranstaltung.anwesenheit.push(doca.data())
                          let len = state.veranstaltung.anwesenheit.length - 1
                          state.veranstaltung.anwesenheit[len].person.vorname =
                            name[0]
                          state.veranstaltung.anwesenheit[len].person.name =
                            name[1]
                        }
                      })
                    }
                  }
                })
              })
          }
        }
      }, 300)
    },
    UNSET_VERANSTALTUNG(state) {
      if (state.veranstaltung.anwesenheitsubscr) {
        state.veranstaltung.anwesenheitsubscr()
      }
      if (state.veranstaltung.gastteamsubscr) {
        state.veranstaltung.gastteamsubscr()
      }
      state.veranstaltung.unsubscribe()
    },
    SET_TRAINER_VERANSTALTUNG(state, id) {
      state.veranstaltung.loader = true
      setTimeout(function () {
        state.veranstaltung.unsubscribe = firebase
          .firestore()
          .collection('User')
          .doc(state.user.data.verein.id)
          .collection('Veranstaltung')
          .doc(id)
          .onSnapshot((doc) => {
            state.veranstaltung.data = doc.data()
            state.veranstaltung.id = id
            state.veranstaltung.anwesenheit = []
            state.veranstaltung.anwesenheitsubscr = firebase
              .firestore()
              .collection('User')
              .doc(state.user.data.verein.id)
              .collection('Veranstaltung')
              .doc(id)
              .collection('Anwesenheit')
              .orderBy('name')
              .onSnapshot((docRef) => {
                state.veranstaltung.anwesenheit = []
                docRef.forEach((doca) => {
                  var person = doca.data()
                  state.veranstaltung.anwesenheit.push(doca.data())
                  var len = state.veranstaltung.anwesenheit.length - 1
                  state.veranstaltung.anwesenheit[len].id = doca.id
                  if (person.weitere_personen) {
                    if (person.weitere_personen.length > 0) {
                      var personen = person.weitere_personen
                      var i, j
                      for (i = 0; i < personen.length; i++) {
                        var name = personen[i].split(' ')
                        for (j = 2; j < name.length; j++) {
                          name[1] += ' ' + name[j]
                        }
                        state.veranstaltung.anwesenheit.push({
                          adresse: person.adresse,
                          datum: person.datum,
                          endedatum: person.endedatum,
                          name: name[1],
                          ort: person.ort,
                          plz: person.plz,
                          telefon: person.telefon,
                          verlassen: person.verlassen,
                          vorname: name[0],
                          zutritt: person.zutritt,
                        })
                        len = state.veranstaltung.anwesenheit.length - 1
                        state.veranstaltung.anwesenheit[len].id = doca.id
                      }
                    }
                  }
                })
              })
            state.veranstaltung.loader = false
          })
      }, 300)
    },
    SET_TEAM(state, id) {
      state.team.unsubscribe = firebase
        .firestore()
        .collection('User')
        .doc(state.user.data.uid)
        .collection('Team')
        .doc(id)
        .onSnapshot((doc) => {
          state.team.data = doc.data()
          state.team.id = id
        })
    },
    UNSET_TEAM(state) {
      state.team.unsubscribe()
    },
  },
  actions: {
    setTimer({ commit }) {
      commit('SET_TIMER')
    },
    setTemplateBackgroundGradient({ commit }, values) {
      commit('SET_TEMPLATE_BG_GRAD', values)
    },
    changeTemplate({ commit }, name) {
      commit('CHANGE_TEMPLATE', name)
    },
    setWettkampf({ commit }, data) {
      commit('SET_WETTKAMPF', data)
    },
    setPublic({ commit }, data) {
      commit('SET_PUBLIC', data)
    },
    closeVeranstaltung({ commit }) {
      commit('UNSET_VERANSTALTUNG')
    },
    loadGastteam({ commit }) {
      commit('LOAD_GASTTEAM')
    },
    loadGast_team({ commit }, team) {
      commit('LOAD_GAST_TEAM', team)
    },
    setGastteamseite({ commit }, daten) {
      commit('SET_GASTTEAM_SEITE', daten)
    },
    addGastteammember({ commit }, daten) {
      commit('ADD_GASTTEAM_MEMBER', daten)
    },
    removeGastteammember({ commit }, daten) {
      commit('REMOVE_GASTTEAM_MEMBER', daten)
    },
    addWKteammember({ commit }, daten) {
      commit('ADD_WETTKAMPF_TEAM_MEMBER', daten)
    },
    removeWKteammember({ commit }, daten) {
      commit('REMOVE_WETTKAMPF_TEAM_MEMBER', daten)
    },
    setVeranstaltung({ commit }, id) {
      commit('SET_VERANSTALTUNG', id)
    },
    setTrainerVeranstaltung({ commit }, id) {
      commit('SET_TRAINER_VERANSTALTUNG', id)
    },
    setPublicVeranstaltung({ commit }, id, alias) {
      commit('SET_PUBLIC_VERANSTALTUNG', id, alias)
    },
    closeTeam({ commit }) {
      commit('UNSET_TEAM')
    },
    setTeam({ commit }, id) {
      commit('SET_TEAM', id)
    },
    anonymerUser({ commit }) {
      commit('SET_USER_ANONYM')
    },
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        var photo =
          'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/profilepictures%2Fdefault.png?alt=media&token=04e6a3c9-cca8-4f7f-9d24-a21a9a2bd648'
        if (user.photoURL) {
          photo = user.photoURL
        }
        if (user.userdata) {
          user.photoUrl = photo
          commit('SET_USER', user)
        } else {
          commit('SET_USER', {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoUrl: photo,
          })
        }
      } else {
        commit('SET_USER', null)
      }
    },
    updateUser({ commit }, data) {
      var database = false
      var userbase = false
      if (data.type == 'photo') {
        commit('SET_USER_PHOTO', data.photo)
      }
      if (data.type == 'name') {
        commit('SET_USER_NAME', data.name)
      }
      if (data.type == 'verein') {
        commit('SET_USER_VEREIN', data.verein)
      }
      if (data.type == 'vereinslogo') {
        commit('SET_USER_VEREIN_LOGO', data.logo)
      }
      if (data.type == 'membership') {
        commit('SET_USER_MEMBERSHIP', data.membership)
      }
      if (data.type == 'status') {
        commit('SET_USER_STATUS', data.status)
      }
    },
    showLoader({ commit }) {
      commit('SET_LOADING', true)
    },
    hideLoader({ commit }) {
      commit('SET_LOADING', false)
    },
    showLoggedOut({ commit }) {
      commit('SET_LOGGED_OUT', true)
    },
    isLoggedIn() {
      return this.state.user.loggedIn
    },
    isLoggedOut() {
      return this.state.user.loggedOut
    },
    setFilterVeranstaltungen({ commit }, data) {
      commit('SET_FILTER_VERANSTALTUNGEN', data)
    },
  },
})
