export default class Sportstaette {
  name = ''
  link = ''
  main = false
  maxzuschauer = ''

  constructor(name, link, main = false, maxzuschauer = '') {
    this.name = name
    this.link = link
    this.main = main
    this.maxzuschauer = maxzuschauer
  }

  toJSON() {
    return {
      name: this.name ? this.name : '',
      link: this.link ? this.link : '',
      main: this.main ? this.main : '',
      maxzuschauer: this.maxzuschauer ? this.maxzuschauer : '',
    }
  }

  getName() {
    return this.name
  }

  getLink() {
    return this.link
  }

  getMain() {
    return this.main
  }

  getMaxzuschauer() {
    return this.maxzuschauer
  }

  setName(name) {
    this.name = name
  }

  setLink(link) {
    this.link = link
  }

  setMain(main) {
    if (main instanceof Boolean) {
      this.main = main
    }
  }

  setMaxZuschauer(max) {
    this.maxzuschauer = max
  }
}
