<template>
  <v-app
    :style="`
      width: 100vw;
      overflow-x: hidden;
      padding-top: env(safe-area-inset-top);
      padding-bottom: env(safe-area-inset-bottom);
    `"
    :dark="!template.light"
    :light="template.light"
  >
    <v-container
      fluid
      v-if="!template.background.image"
      :style="`
        position: fixed;
        height: 100vh;
        overflow: hidden;
        background-color: #dc0c23;
        background-image: linear-gradient(180deg, ${template.background.primary} ${template.background.grad_start},${template.background.secondary} ${template.background.grad_end});
        background-attachment: fixed;
        background-size: cover;
      `"
    ></v-container>
    <v-container
      fluid
      style="position: fixed; padding: 0;"
      v-if="template.background.image"
    >
      <v-img
        width="100vw"
        height="100vh"
        :src="template.background.image"
        position="center center"
        gradient="to top left, rgba(0,0,0,.1), rgba(0,0,0,0.7)"
      />
    </v-container>
    <v-container
      fluid
      style="
        position: fixed;
        z-index: 100;
        bottom: 0;
        padding: 0;
        width: 100vw;
        background-color: #005e3f;
        color: #ffffff;
        border-top: 5px solid #005e3f;
        padding-bottom: env(safe-area-inset-bottom);
      "
      v-if="
        $vuetify.breakpoint.mdAndDown &&
        template.var.sponsor == 'AOK' &&
        $route.path != '/impressum' &&
        $route.path != '/datenschutz'
      "
    >
      <v-row justify="center" class="px-1 py-0">
        <v-col
          cols="10"
          sm="6"
          align-self="center"
          class="text-right my-0 py-0"
        >
          <v-img
            contain
            src="/img/flvw_check-in-app_aok_logo.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      style="
        position: fixed;
        z-index: 8887;
        bottom: 0;
        padding: 0;
        width: 100vw;
        padding-bottom: env(safe-area-inset-bottom);
      "
      v-if="
        $vuetify.breakpoint.lgAndUp &&
        template.var.sponsor == 'AOK' &&
        $route.path != '/impressum' &&
        $route.path != '/datenschutz'
      "
    >
      <v-row justify="space-between" class="px-3">
        <v-col cols="3" align-self="end"></v-col>
        <v-col cols="3" align-self="center" class="py-0">
          <v-row justify="end">
            <v-col
              cols="10"
              align-self="center"
            >
              <v-img contain src="/img/flvw_check-in-app_aok_logo.png"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div
      style="
        position: fixed;
        z-index: 8887;
        right: 2vw;
        bottom: 2vw;
        width: 12vw;
        padding-bottom: env(safe-area-inset-bottom);
      "
      v-if="
        $vuetify.breakpoint.mdAndUp &&
        template.var.sponsor == 'IKK' &&
        $route.path != '/impressum' &&
        $route.path != '/datenschutz' &&
        !user.loader
      "
    >
      <a href="https://www.ikk-classic.de/" target="_blank">
        <v-img contain src="/fvm/sponsor/IKK_Desktop.jpg"></v-img>
      </a>
    </div>
    <div
      style="
        position: fixed;
        z-index: 100;
        bottom: 0;
        width: 100vw;
        padding-bottom: env(safe-area-inset-bottom);
        background-color: #0ca1e3;
      "
      v-if="
        $vuetify.breakpoint.smAndDown &&
        template.var.sponsor == 'IKK' &&
        $route.path != '/impressum' &&
        $route.path != '/datenschutz' &&
        !user.loader
      "
    >
      <a href="https://www.ikk-classic.de/" target="_blank">
        <v-img contain src="/fvm/sponsor/IKK_Mobile.jpg"></v-img>
      </a>
    </div>
    <div
      v-if="!user.loader"
      :style="
        $vuetify.breakpoint.smAndDown && template.var.sponsor == 'IKK'
          ? 'padding-bottom: 5vh;'
          : undefined
      "
    >
      <v-fade-transition hide-on-leave>
        <router-view></router-view>
      </v-fade-transition>
    </div>
    <v-fade-transition>
      <loader v-if="user.loader" />
    </v-fade-transition>
  </v-app>
</template>

<script>
import loader from './components/loader/loader'
import { mapGetters } from 'vuex'
import store from './store'
//import firebase from './firebaseConfig'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  components: {
    loader,
  },
}
</script>

<style>
*::-webkit-scrollbar {
  display: none;
}

.v-bottom-navigation {
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
}

.v-bottom-navigation >>> .v-btn__content {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: constant(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.0 */
  margin-bottom: (
    10px + env(safe-area-inset-bottom)
  ); /* move footer up on iPhone X - iOS 11.2 */
}

.v-app-bar,
.v-app-bar--fixed {
  z-index: 9000 !important;
}

.v-overlay {
  z-index: 9990 !important;
}

.v-dialog {
  z-index: 9998 !important;
}
.v-dialog__content {
  z-index: 9999 !important;
}

.v-menu__content {
  border-radius: 20px !important;
}

.v-btn {
  color: #ffffff;
}
</style>
