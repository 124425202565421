var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:("\n    width: 100vw;\n    overflow-x: hidden;\n    padding-top: env(safe-area-inset-top);\n    padding-bottom: env(safe-area-inset-bottom);\n  "),attrs:{"dark":!_vm.template.light,"light":_vm.template.light}},[(!_vm.template.background.image)?_c('v-container',{style:(("\n      position: fixed;\n      height: 100vh;\n      overflow: hidden;\n      background-color: #dc0c23;\n      background-image: linear-gradient(180deg, " + (_vm.template.background.primary) + " " + (_vm.template.background.grad_start) + "," + (_vm.template.background.secondary) + " " + (_vm.template.background.grad_end) + ");\n      background-attachment: fixed;\n      background-size: cover;\n    ")),attrs:{"fluid":""}}):_vm._e(),(_vm.template.background.image)?_c('v-container',{staticStyle:{"position":"fixed","padding":"0"},attrs:{"fluid":""}},[_c('v-img',{attrs:{"width":"100vw","height":"100vh","src":_vm.template.background.image,"position":"center center","gradient":"to top left, rgba(0,0,0,.1), rgba(0,0,0,0.7)"}})],1):_vm._e(),(
      _vm.$vuetify.breakpoint.mdAndDown &&
      _vm.template.var.sponsor == 'AOK' &&
      _vm.$route.path != '/impressum' &&
      _vm.$route.path != '/datenschutz'
    )?_c('v-container',{staticStyle:{"position":"fixed","z-index":"100","bottom":"0","padding":"0","width":"100vw","background-color":"#005e3f","color":"#ffffff","border-top":"5px solid #005e3f","padding-bottom":"env(safe-area-inset-bottom)"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-1 py-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-right my-0 py-0",attrs:{"cols":"10","sm":"6","align-self":"center"}},[_c('v-img',{attrs:{"contain":"","src":"/img/flvw_check-in-app_aok_logo.png"}})],1)],1)],1):_vm._e(),(
      _vm.$vuetify.breakpoint.lgAndUp &&
      _vm.template.var.sponsor == 'AOK' &&
      _vm.$route.path != '/impressum' &&
      _vm.$route.path != '/datenschutz'
    )?_c('v-container',{staticStyle:{"position":"fixed","z-index":"8887","bottom":"0","padding":"0","width":"100vw","padding-bottom":"env(safe-area-inset-bottom)"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-3",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3","align-self":"end"}}),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","align-self":"center"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"10","align-self":"center"}},[_c('v-img',{attrs:{"contain":"","src":"/img/flvw_check-in-app_aok_logo.png"}})],1)],1)],1)],1)],1):_vm._e(),(
      _vm.$vuetify.breakpoint.mdAndUp &&
      _vm.template.var.sponsor == 'IKK' &&
      _vm.$route.path != '/impressum' &&
      _vm.$route.path != '/datenschutz' &&
      !_vm.user.loader
    )?_c('div',{staticStyle:{"position":"fixed","z-index":"8887","right":"2vw","bottom":"2vw","width":"12vw","padding-bottom":"env(safe-area-inset-bottom)"}},[_c('a',{attrs:{"href":"https://www.ikk-classic.de/","target":"_blank"}},[_c('v-img',{attrs:{"contain":"","src":"/fvm/sponsor/IKK_Desktop.jpg"}})],1)]):_vm._e(),(
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.template.var.sponsor == 'IKK' &&
      _vm.$route.path != '/impressum' &&
      _vm.$route.path != '/datenschutz' &&
      !_vm.user.loader
    )?_c('div',{staticStyle:{"position":"fixed","z-index":"100","bottom":"0","width":"100vw","padding-bottom":"env(safe-area-inset-bottom)","background-color":"#0ca1e3"}},[_c('a',{attrs:{"href":"https://www.ikk-classic.de/","target":"_blank"}},[_c('v-img',{attrs:{"contain":"","src":"/fvm/sponsor/IKK_Mobile.jpg"}})],1)]):_vm._e(),(!_vm.user.loader)?_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown && _vm.template.var.sponsor == 'IKK'
        ? 'padding-bottom: 5vh;'
        : undefined)},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('router-view')],1)],1):_vm._e(),_c('v-fade-transition',[(_vm.user.loader)?_c('loader'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }