import Vue from 'vue'
import App from './App.vue'
import router from './routes/index'
import store from './store'
import vuetify from './plugins/vuetify'
import Clipboard from 'v-clipboard'
import VImageInput from 'vuetify-image-input/a-la-carte'
import JsonCSV from 'vue-json-csv'
import VueSignature from 'vue-signature-pad'
import Zeit from './classes/zeit'
import Person from './classes/person'
import Verein from './classes/verein'
import Sportstaette from './classes/sportstaette'

Vue.use(Clipboard)
Vue.use(VueSignature)
Vue.component('VImageInput', VImageInput)

Vue.component('downloadCsv', JsonCSV)

window.Zeit = Zeit
window.Person = Person
window.Sportstaette = Sportstaette
window.Verein = Verein

Vue.config.productionTip = true
Vue.config.silent = true
Vue.config.devtools = false
//Vue.config.productionTip = false
//Vue.config.silent = false
//Vue.config.devtools = true

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
