const Veranstaltungen = () =>
  import('../components/admin/veranstaltung/start.vue')
const Veranstaltung = () =>
  import('../components/admin/veranstaltung/index.vue')
const NichtZugeordnet = () =>
  import(
    '../components/admin/veranstaltung/anwesenheitsliste/andere-veranstaltung.vue'
  )
const Auswahl = () =>
  import('../components/admin/veranstaltung/veranstaltung.vue')
const Bearbeiten = () => import('../components/admin/veranstaltung/edit.vue')
const EditTeamAnwesenheit = () =>
  import('../components/admin/veranstaltung/edit-team-anwesenheit.vue')
const EditSchiedsrichterAnwesenheit = () =>
  import('../components/admin/veranstaltung/schiedsrichter-anwesenheit.vue')
const GastTeamAnwesenheit = () =>
  import('../components/admin/veranstaltung/gaesteteam.vue')
const Anwesenheitsliste = () =>
  import('../components/admin/veranstaltung/anwesenheitsliste.vue')
const ZuschauerBearbeiten = () =>
  import(
    '../components/admin/veranstaltung/anwesenheitsliste/zuschauer-edit.vue'
  )
const NichtZugeordnetZuschauer = () =>
  import(
    '../components/admin/veranstaltung/anwesenheitsliste/anderer-zuschauer-edit.vue'
  )
const PDFInfo = () => import('../components/admin/veranstaltung/pdf-info.vue')

/* LA WETTKAMPF MODUL */
const LA_Wettkampf = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/wettkampf.vue'
  )
const LA_Wettkampf_Kontrolle = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/einlasskontrolle/dashboard.vue'
  )
const LA_Wettkampf_bearbeiten = () =>
  import('../components/admin/veranstaltung/leichtathletik/wettkampf/edit.vue')
const LA_Wk_Disziplinen = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/disziplinen.vue'
  )
const LA_Wk_Disziplin = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/disziplin.vue'
  )
const LA_Wk_Disziplin_Bearbeiten = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/disziplin_bearbeiten.vue'
  )
const LA_Wk_Disziplin_Anwesenheit = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/disziplin_anwesenheit.vue'
  )
const LA_Wk_Anwesenheitsliste = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/anwesenheitsliste.vue'
  )
const LA_Wk_Disziplinen_csvimport = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/disziplinen_csv_import.vue'
  )
const LA_Wk_Anmeldungen_verwalten = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/anmeldungen_verwalten.vue'
  )
const LA_Wk_Anmeldung_bearbeiten = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/anmeldung_bearbeiten.vue'
  )
const LA_Wk_Anmeldungen_csvimport = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/anmeldungen_csv_import.vue'
  )
const LA_Wk_Anwesenheit_verwalten = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/anwesenheit_verwalten.vue'
  )
const LA_Wk_Anwesenheit_bearbeiten = () =>
  import(
    '../components/admin/veranstaltung/leichtathletik/wettkampf/anwesenheit_bearbeiten.vue'
  )

const veranstaltungsroutes = {
  start: {
    path: '/start',
    component: Veranstaltungen,
    meta: {
      requiresAuth: true,
    },
  },
  veranstaltung: {
    path: '/veranstaltung/:veranstaltung',
    component: Veranstaltung,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: Auswahl,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'bearbeiten',
        component: Bearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'schiedsrichter-erfassen',
        component: EditSchiedsrichterAnwesenheit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'team-erfassen',
        component: EditTeamAnwesenheit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'gastteam-erfassen',
        component: GastTeamAnwesenheit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anwesenheitsliste',
        component: Anwesenheitsliste,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anwesenheitsliste/:zuschauer',
        component: ZuschauerBearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'zuschauer-pdf/:alias',
        component: PDFInfo,
      },
    ],
  },
  wettkampf: {
    path: '/wettkampf/:veranstaltung',
    component: Veranstaltung,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: LA_Wettkampf,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'wettkampfkontrolle',
        component: LA_Wettkampf_Kontrolle,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'wettkampfkontrolle/:sportstaette',
        component: LA_Wettkampf_Kontrolle,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'bearbeiten',
        component: LA_Wettkampf_bearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'disziplinen',
        component: LA_Wk_Disziplinen,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'disziplinen/csvimport',
        component: LA_Wk_Disziplinen_csvimport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'disziplinen/:disziplin',
        component: LA_Wk_Disziplin,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'disziplinen/:disziplin/bearbeiten',
        component: LA_Wk_Disziplin_Bearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'disziplinen/:disziplin/anwesenheit',
        component: LA_Wk_Disziplin_Anwesenheit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anmeldungen',
        component: LA_Wk_Anmeldungen_verwalten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anmeldungen-csvimport',
        component: LA_Wk_Anmeldungen_csvimport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anmeldungen/:anmeldung',
        component: LA_Wk_Anmeldung_bearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anwesenheit',
        component: LA_Wk_Anwesenheit_verwalten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anwesenheit/:anmeldung',
        component: LA_Wk_Anwesenheit_bearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'team-erfassen',
        component: EditTeamAnwesenheit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'gastteam-erfassen',
        component: GastTeamAnwesenheit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anwesenheitsliste',
        component: LA_Wk_Anwesenheitsliste,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'anwesenheitsliste/:zuschauer',
        component: ZuschauerBearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'zuschauer-pdf/:alias',
        component: PDFInfo,
      },
    ],
  },
}

export default veranstaltungsroutes
