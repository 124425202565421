export function getTemplate(template) {
  var app = process.env.VUE_APP_VERBANDNAME

  var templateObj = {
    isDark: '',
  }
  templateObj.verbaende = [
    {
      id: 'FLVW',
      name: 'FLVW-CheckIn',
      logo: '/img/verbaende/flvw.png',
      url: 'https://flvw.app',
      color: '#dc0c23',
      textcolor: '#ffffff',
    },
    {
      id: 'FVM',
      name: 'FVM-CheckIn',
      logo: '/img/verbaende/fvm.png',
      url: 'https://fvm-checkin.app',
      color: 'rgb(61,149,48)',
      textcolor: '#ffffff',
    },
    {
      id: 'BFV',
      name: 'BFV Service-App',
      logo: '/img/verbaende/bfv.png',
      url: 'https://bfv-service.app',
      color: 'rgb(227,6,19)',
      textcolor: '#ffffff',
    },
    {
      id: 'DEU',
      name: 'DEU-CheckIn',
      logo: '/img/verbaende/deu.png',
      url: 'https://eislauf-union.web.app',
      color: '#0074b5',
      textcolor: '#ffffff',
    },
  ]

  templateObj.getVerband = (id) => {
    switch (id) {
      case 'BFV':
        return templateObj.verbaende[2]
      case 'FLVW':
        return templateObj.verbaende[0]
      case 'FVM':
        return templateObj.verbaende[1]
      case 'DEU':
        return templateObj.verbaende[0]
      default:
        return templateObj.verbaende[0]
    }
  }

  templateObj.isDark = (color) => {
    // Variables for red, green, blue values
    var r, g, b, hsp

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
      )

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false
    } else {
      return true
    }
  }

  if (app == 'DEU') {
    templateObj.modules = {
      control: {
        buttons: '#dc0c23',
        buttons_text: '#FFFFFF',
        box: '#FFFFFF',
        box_text: '#000000',
        box_secondary: '#0074b5',
        box_secondary_text: '#FFFFFF',
        dialog: '#0074b5',
      },
    }
    templateObj.colors = {
      navigation: '#E3F2FD',
      navigation_text: '#0074b5',
      navigation_inline_text: '#0074b5',
      navigation_blocks: '#0074b5',
      navigation_blocks_text: '#E3F2FD',
      primary: '#0074b5',
      inline_primary_text: '#E3F2FD',
      secondary: '#E3F2FD',
      inline_secondary_text: '#0074b5',
      blocks: '#E3F2FD',
      block_text: '#0074b5',
      inline_background_text: '#FFFFFF',
    }
    templateObj.login = {
      block: 'rgba(0,0,0,0.5)',
    }
    templateObj.background = {
      primary: '#dc0c23',
      secondary: '#dddddd',
      grad_start: '28%',
      grad_end: '100%',
      image: '/img/deu-background-3.jpg',
    }
    templateObj.var = {
      app_url: 'https://eislauf-union.web.app',
      logo: 'deu-checkin.png',
      logo_light: 'deu-checkin-light.png',
      app_logo: 'deu-app-icon-light.png',
      logo_only: true,
      app_name: 'CheckIn',
      verband: 'DEU',
      arten: ['Training', 'Wettkampf', 'Versammlung'],
      anleitungen: {
        allgemein: '/flvw/anleitungen/FLVW_Anleitung_Allgemein.pdf',
        registrierung: '/flvw/anleitungen/anleitung_registrierung.pdf',
        schiedsrichter: '/flvw/anleitungen/schnellstart_schiedsrichter.pdf',
        gastteam: '/flvw/anleitungen/schnellstart_gastmannschaft.pdf',
      },
      qrcodeplakat: '/flvw/vorlage_qrcode.png',
    }
  } else if (app == 'FLVW') {
    templateObj.modules = {
      control: {
        buttons: '#dc0c23',
        buttons_text: '#FFFFFF',
        box: '#FFFFFF',
        box_text: '#000000',
        box_secondary: '#DDDDDD',
        box_secondary_text: '#000000',
        dialog: '#222222',
      },
    }
    templateObj.colors = {
      navigation: '#dc0c23',
      navigation_text: '#FFFFFF',
      navigation_inline_text: '#FFFFFF',
      navigation_blocks: '#dddddd',
      navigation_blocks_text: '#111111',
      primary: '#dc0c23',
      inline_primary_text: '#FFFFFF',
      secondary: '#dddddd',
      inline_secondary_text: '#111111',
      blocks: '#FFFFFF',
      block_text: '#000000',
      inline_background_text: '#FFFFFF',
      success: '#00cc33',
      success_text: '#FFFFFF',
      danger: '#dc0c23',
      danger_text: '#FFFFFF',
    }
    templateObj.background = {
      primary: '#dc0c23',
      secondary: '#dddddd',
      grad_start: '28%',
      grad_end: '100%',
      image: '/img/bg_05.jpg',
    }
    templateObj.login = {
      block: 'rgba(0,0,0,0.5)',
    }
    templateObj.var = {
      app_url: window.location.protocol + '//' + window.location.host,
      authDomain: 'auth.flvw.app',
      logo: 'flvw-checkin-white.png',
      logo_light: 'flvw-checkin.png',
      app_logo: 'flvw-app-icon.png',
      logo_only: true,
      app_name: 'FLVW-CheckIn',
      full_app_name: 'die FLVW-CheckIn App',
      verband: 'FLVW',
      sponsor: 'AOK',
      arten: [
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
        'Auswärtsspiel',
        'Training',
        'Wettkampf',
        'Versammlung',
      ],
      standard_aktivitaeten: [
        'Sportler',
        'Zuschauer',
        'Trainer',
        'Betreuer',
        'Schiedsrichter',
      ],
      public_aktivitaeten: ['Zuschauer'],
      anleitungen: {
        allgemein: '/flvw/anleitungen/FLVW_Anleitung_Allgemein.pdf',
        registrierung: '/flvw/anleitungen/anleitung_registrierung.pdf',
        schiedsrichter: '/flvw/anleitungen/schnellstart_schiedsrichter.pdf',
        gastteam: '/flvw/anleitungen/schnellstart_gastmannschaft.pdf',
      },
      qrcodeplakat: '/flvw/vorlage_qrcode.png',
      qrcodeplakat_sportler: '/flvw/vorlage_qrcode_sportler.png',
    }
  } else if (app == 'FVM') {
    templateObj.modules = {
      control: {
        buttons: '#067b4a',
        buttons_text: '#FFFFFF',
        box: '#FFFFFF',
        box_text: '#000000',
        box_secondary: '#DDDDDD',
        box_secondary_text: '#000000',
        dialog: '#222222',
      },
    }
    templateObj.colors = {
      navigation: '#067b4a',
      navigation_text: '#FFFFFF',
      navigation_inline_text: '#FFFFFF',
      navigation_blocks: '#dddddd',
      navigation_blocks_text: '#111111',
      primary: '#067b4a',
      inline_primary_text: '#FFFFFF',
      secondary: '#dddddd',
      inline_secondary_text: '#111111',
      blocks: '#FFFFFF',
      block_text: '#000000',
      inline_background_text: '#FFFFFF',
      success: '#00cc33',
      success_text: '#FFFFFF',
      danger: '#dc0c23',
      danger_text: '#FFFFFF',
    }
    templateObj.background = {
      primary: '#067b4a',
      secondary: '#dddddd',
      grad_start: '28%',
      grad_end: '100%',
      image: '/img/bg-stadium.jpg',
    }
    templateObj.login = {
      block: 'rgba(0,0,0,0.5)',
    }
    templateObj.var = {
      app_url: window.location.protocol + '//' + window.location.host,
      authDomain: 'auth.fvm-checkin.app',
      logo: 'fvm-checkin.png',
      logo_light: 'fvm-checkin-light.png',
      app_logo: 'fvm-app-icon.png',
      logo_only: true,
      app_name: 'FVM-CheckIn',
      full_app_name: 'die FVM-CheckIn App',
      verband: 'FVM',
      sponsor: 'IKK',
      arten: [
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
        'Auswärtsspiel',
        'Training',
        'Wettkampf',
        'Versammlung',
      ],
      standard_aktivitaeten: [
        'Sportler',
        'Zuschauer',
        'Trainer',
        'Betreuer',
        'Schiedsrichter',
      ],
      public_aktivitaeten: ['Zuschauer'],
      nationen: ['Deutschland', 'Niederlande', 'Belgien'],
      anleitungen: {
        allgemein: '/fvm/anleitungen/FVM_Anleitung_Allgemein.pdf',
        registrierung: '/fvm/anleitungen/anleitung_registrierung.pdf',
        schiedsrichter: '/fvm/anleitungen/schnellstart_schiedsrichter.pdf',
        gastteam: '/fvm/anleitungen/schnellstart_gastmannschaft.pdf',
      },
      qrcodeplakat: '/fvm/vorlage_qrcode.png',
      qrcodeplakat_sportler: '/fvm/vorlage_qrcode_sportler.png',
    }
    templateObj.impressum = {
      herausgeber:
        'Fußball-Verband Mittelrhein e.V.<br>Sövener Str. 60<br>53773 Hennef<br><br>Telefon: 02242/91875-0<br>Fax: 02242/91875-55<br>E-Mail: support@fvm.de',
      vertreten_durch:
        'Präsident: Bernd Neuendorf<br>Vizepräsident: Hans-Christian Olpen<br>Vizepräsidentin: Johanna Sandvoß<br>Schatzmeister: Ewald Prinz<br>Verbandsgeschäftsführer: Dirk Brennecke (beratendes Präsidiumsmitglied)',
      registereintrag:
        'Vereinsregister: Amtsgericht Siegburg, VR 3338<br>St-Nr.: 220/5946/0113 Finanzamt Siegburg<br>USt-ID.: DE122661926',
      urheberrecht:
        'Der Inhalt dieser Seiten ist urheberrechtlich geschützt. Die angebotenen Inhalte und Funktionen sind für den Gebrauch im Rahmen der satzungsmäßigen Aufgaben der Vereine des FVM bestimmt. Jede weitergehende Verwendung ohne schriftliche Zustimmung des Fussball-Verbandes Mittelrhein e.V., insbesondere jede Form kommerzieller Nutzung, ist untersagt.',
      haftung:
        'Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',
    }
    templateObj.datenschutz = {
      link: 'https://www.fvm.de/datenschutz/',
    }
  } else if (app == 'BFV') {
    templateObj.modules = {
      control: {
        buttons: '#c9020d',
        buttons_text: '#FFFFFF',
        box: '#FFFFFF',
        box_text: '#000000',
        box_secondary: '#DDDDDD',
        box_secondary_text: '#000000',
        dialog: '#222222',
      },
    }
    templateObj.colors = {
      navigation: '#c9020d',
      navigation_text: '#FFFFFF',
      navigation_inline_text: '#FFFFFF',
      navigation_blocks: '#dddddd',
      navigation_blocks_text: '#111111',
      primary: '#c9020d',
      inline_primary_text: '#FFFFFF',
      secondary: '#dddddd',
      inline_secondary_text: '#111111',
      blocks: '#FFFFFF',
      block_text: '#000000',
      inline_background_text: '#FFFFFF',
      success: '#00cc33',
      success_text: '#FFFFFF',
      danger: '#dc0c23',
      danger_text: '#FFFFFF',
    }
    templateObj.background = {
      primary: '#c9020d',
      secondary: '#e30613',
      grad_start: '28%',
      grad_end: '100%',
      image: '/img/bg-bfv.jpg',
    }
    templateObj.login = {
      block: 'rgba(0,0,0,0.5)',
    }
    templateObj.var = {
      app_url: window.location.protocol + '//' + window.location.host,
      authDomain: 'auth.bfv-service.app',
      logo: 'bfv-service-light.png',
      logo_light: 'bfv-service-light.png',
      app_logo: 'bfv-service-app-icon-light.png',
      logo_only: true,
      app_name: 'BFV Service-App',
      full_app_name: 'die BFV Service-App',
      verband: 'BFV',
      sponsor: '',
      arten: [
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
        'Auswärtsspiel',
        'Training',
        'Versammlung',
      ],
      standard_aktivitaeten: [
        'Sportler',
        'Zuschauer',
        'Trainer',
        'Betreuer',
        'Schiedsrichter',
      ],
      public_aktivitaeten: ['Zuschauer'],
      anleitungen: {
        allgemein:
          '/bfv/anleitungen/BerlinerFV_Service_Anleitung_Allgemein.pdf',
        registrierung: '/bfv/anleitungen/anleitung_registrierung.pdf',
        schiedsrichter: '/bfv/anleitungen/schnellstart_schiedsrichter.pdf',
        gastteam: '/bfv/anleitungen/schnellstart_gastmannschaft.pdf',
      },
      qrcodeplakat: '/bfv/vorlage_qrcode.png',
      deactivate_abteilung: true,
    }
    templateObj.impressum = {
      herausgeber:
        'Berliner Fußball-Verband e. V.<br>Humboldtstraße 8a<br>14193 Berlin<br><br>Tel: (030) 896994-177<br>Fax: 030 / 89 69 94-101<br>E-Mail: service@berlinerfv.de',
      vertreten_durch:
        'Bernd Schultz (Präsident)<br>Jörg Wirtgen (Vizepräsident Marketing & Öffentlichkeitsarbeit)<br>Ralph Rose (Vizepräsident Finanzen)<br>Jan Schlüschen (Vizepräsident Recht)<br>Kevin Langner (Geschäftsführer)',
      registereintrag:
        'Registergericht: Amtsgericht Charlottenburg, VR 846 B<br>St-Nr.: 27/ 610 / 50590<br>USt-ID.: DE 136 621 394',
      urheberrecht:
        'Der Inhalt dieser Seiten ist urheberrechtlich geschützt. Die angebotenen Inhalte und Funktionen sind für den Gebrauch im Rahmen der satzungsmäßigen Aufgaben der Vereine des Berliner Fußball-Verband bestimmt. Jede weitergehende Verwendung ohne schriftliche Zustimmung des Berliner Fußball-Verband e.V., insbesondere jede Form kommerzieller Nutzung, ist untersagt.',
      haftung:
        'Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',
    }
  }
  return templateObj
}

export default { getTemplate }
