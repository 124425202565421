/* PUBLIC SITE */
const Publicsite = () => import('../components/public/index.vue')
const Publicstart = () => import('../components/public/start.vue')
const Teamerfassung = () => import('../components/public/gastteam/gastteam.vue')
const Publicevents = () => import('../components/public/erfassung.vue')
const Publicfullfill = () =>
  import('../components/public/profil-vervollstaendigen.vue')
const Publicveranstaltungzs = () => import('../components/public/checkedin.vue')
const Sporltercheckin = () =>
  import('../components/public_la/anmeldung_checkin.vue')

//LA WETTKAMPF PUBLIC
const LA_Public_Site = () => import('../components/public_la/index.vue')
const LA_Public_Start = () => import('../components/public_la/start.vue')
const LA_Public_Sportler = () =>
  import('../components/public_la/einzelperson.vue')
const LA_Public_Team = () =>
  import('../components/public_la/team/teamanmeldung.vue')

const PageNotFound = () => import('../components/page_not_found.vue')

const publicroutes = {
  guestteams: {
    path: '/g',
    name: 'Teamerfassung',
    component: Publicsite,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
    children: [
      {
        path: ':verein/:gastparam',
        name: 'Übersicht',
        component: Teamerfassung,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
        },
      },
    ],
  },
  wettkampf: {
    path: '/wk',
    name: 'Sportlererfassung',
    component: LA_Public_Site,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
    children: [
      {
        path: ':verein/:veranstaltung',
        name: 'Übersicht',
        component: LA_Public_Start,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
        },
      },
      {
        path: ':verein/:veranstaltung/einzelperson',
        name: 'Sportler-Erfassung',
        component: LA_Public_Sportler,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
        },
      },
      {
        path: ':verein/:veranstaltung/team',
        name: 'Sportler-Team-Erfassung',
        component: LA_Public_Team,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
        },
      },
      { path: '*', component: PageNotFound },
    ],
  },
  verein: {
    path: '/:verein',
    name: 'Vereinsseite',
    component: Publicsite,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
      verein: true,
    },
    children: [
      {
        path: '',
        name: 'Übersicht',
        component: Publicstart,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: 'erfassen',
        name: 'Übersicht',
        component: Publicevents,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: 'vervollstaendigen',
        name: 'Übersicht',
        component: Publicfullfill,
        meta: {
          requiresAuth: true,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: 'sportler/:wettkampf/:anmeldung',
        name: 'Sportler-CheckIn',
        component: Sporltercheckin,
        meta: {
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: ':sportstaette',
        name: 'Übersicht-Sportstätte',
        component: Publicstart,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: ':sportstaette/erfassen',
        name: 'Übersicht',
        component: Publicevents,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: ':sportstaette/vervollstaendigen',
        name: 'Übersicht',
        component: Publicfullfill,
        meta: {
          requiresAuth: true,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: ':sportstaette/zuschauer/:zuschauer',
        name: 'Übersicht',
        component: Publicveranstaltungzs,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: 'zuschauer/:zuschauer',
        name: 'Übersicht',
        component: Publicveranstaltungzs,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      {
        path: 'sportler/:zuschauer',
        name: 'Übersicht',
        component: Publicveranstaltungzs,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          verein: true,
        },
      },
      { path: '*', component: PageNotFound },
    ],
  },
  pagenotfound: { path: '*', component: PageNotFound },
}

export default publicroutes
