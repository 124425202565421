import Vue from 'vue'
//import VueI18n from 'vue-i18n'
import Vuetify from 'vuetify/lib'
import style from '../styles/template'

Vue.use(Vuetify)

const theme_data = style.getTemplate()
/*Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'de',
  de: {
    public: {
      hygienekonzept: {
        name: 'Hygienekonzept',
        button: 'Hygienekonzept ansehen',
      },
      startseite: {
        titel: 'Personenerfassung',
        untertitel: 'Bitte wähle aus, wie deine Daten erfasst werden sollen:',
        einmalig: {
          titel: 'Einmalige Erfassung',
          text:
            'Deine Daten werden nur für eine bestimmte Veranstaltung erfasst und nicht permanent gespeichert. Du musst deine Daten für jede weitere Veranstaltung eingeben.',
        },
        login: {
          titel: 'Login',
          text:
            'Wenn du bereits einen Account hast, dann kannst du dich einloggen und dich in die Anwesenheitsliste eintragen.',
        },
        registrierung: {
          titel: 'Registrierung',
          text:
            'Erstelle einen "Zuschauer-Account" und trage dich zukünftig innerhalb von Sekunden in die Anwesenheitslisten von Veranstaltungen ein. Und zwar vereinsübergreifend - bei allen Veranstaltungen, wo die App zur Personenerfassung verwendert wird.',
        },
      },
    },
    formular: {
      vorname: 'Vorname',
      name: 'Nachname',
      adresse: 'Straße + Nr.',
      plz: 'Postleitzahl',
      ort: 'Ort',
      telefon: 'Telefon',
    },
  },
  nl: {
    hygienekonzept: {
      name: 'Hygiëne concept',
      button: 'Bekijk het hygiëneconcept',
    },
    startseite: {
      titel: 'Persoonsregistratie',
      untertitel: 'Kies hoe uw gegevens moeten worden vastgelegd:',
      einmalig: {
        titel: 'Eenmalige acquisitie',
        text:
          'Uw gegevens worden alleen geregistreerd voor een specifieke gebeurtenis en worden niet permanent opgeslagen. Voor elk volgend evenement moet u uw gegevens invoeren.',
      },
      login: {
        titel: 'Log in',
        text:
          'Als je al een account hebt, kun je inloggen en jezelf toevoegen aan de presentielijst.',
      },
      registrierung: {
        titel: 'Registratie',
        text:
          'Maak een "toeschouwersaccount" aan en voeg jezelf voortaan binnen enkele seconden toe aan de presentielijsten van evenementen. En in alle clubs - bij alle evenementen waar de app wordt gebruikt om mensen op te nemen.',
      },
    },
    formular: {
      vorname: 'Voornaam',
      name: 'Achternamm',
      adresse: 'Straat / huis nummer',
      plz: 'Postcode',
      ort: 'Stad',
      telefon: 'telefoon',
    },
  },
  en: {
    public: {
      hygienekonzept: {
        name: 'Hygiene concept',
        button: 'Show hygiene concept',
      },
      startseite: {
        titel: 'Person registration',
        untertitel: 'Please select how your data should be recorded:',
        einmalig: {
          titel: 'One-time acquisition',
          text:
            'Your data will only be recorded for a specific event and will not be stored permanently. You have to enter your details for each additional event.',
        },
        login: {
          titel: 'Sign in',
          text:
            'If you already have an account you can sign in and add yourself to the attendance list.',
        },
        registrierung: {
          titel: 'Create an account',
          text:
            'Create a "visitor account" and add yourself to the attendance lists of events within seconds in future. Across all clubs where the app is used to record people.',
        },
      },
    },
    formular: {
      vorname: 'first name',
      name: 'last name',
      adresse: 'street, nr',
      plz: 'zip code',
      ort: 'city',
      telefon: 'phone',
    },
  },
  fr: {
    public: {
      hygienekonzept: {
        name: "Concept d'hygiène",
        button: "Voir le concept d'hygiène",
      },
      startseite: {
        titel: 'Inscription des personnes',
        untertitel:
          'Veuillez choisir comment vos données doivent être enregistrées:',
        einmalig: {
          titel: 'Acquisition unique',
          text:
            'Vos données ne seront enregistrées que pour un événement spécifique et ne seront pas stockées de manière permanente. Vous devez saisir vos coordonnées pour chaque événement supplémentaire.',
        },
        login: {
          titel: 'Connexion',
          text:
            'Si vous avez déjà un compte, vous pouvez vous connecter et vous ajouter à la liste de présence.',
        },
        registrierung: {
          titel: 'Inscription',
          text: `Créez un "compte spectateur" et à l'avenir ajoutez-vous aux listes de présence des événements en quelques secondes. Et dans tous les clubs - à tous les événements où l'application est utilisée pour enregistrer des personnes.`,
        },
      },
    },
    formular: {
      vorname: 'Prénom',
      name: 'Nom de famille',
      adresse: 'Rue, non',
      plz: 'Code postal',
      ort: 'ville',
      telefon: 'téléphone',
    },
  },
})*/

const opts = {
  /*lang: {
    current: 'de',
    t: (key, ...params) => i18n.t(key, params),
  },*/
  theme: {
    dark: theme_data.isDark(theme_data.colors.primary),
    themes: {
      dark: {
        primary: theme_data.colors.primary,
      },
      light: {
        primary: theme_data.colors.primary,
      },
    },
  },
}

export default new Vuetify(opts)
