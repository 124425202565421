import store from '../store'
import router from '../routes/index'
import style from '../styles/template'

let snap
let vereinsnap

export function getUser() {
  return firebase.auth().currentUser
}
export function logout() {
  firebase
    .auth()
    .signOut()
    .then(function () {
      store.dispatch('showLoggedOut')
    })
    .catch(function (error) {
      // An error happened.
      console.log(error)
    })
}
export default { getUser, logout }

if (process.env.NODE_ENV == 'production') {
  fetch('/__/firebase/init.json').then(async (response) => {
    let config = await response.json()
    config.authDomain = style.getTemplate().var.authDomain
    firebase.initializeApp(config)
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!user.isAnonymous) {
          var userprovider = firebase.auth().currentUser

          // GET PROVIDER INFORMATIONS
          var providername = 'password'
          if (userprovider != null) {
            userprovider.providerData.forEach((profile) => {
              providername = profile.providerId
            })
          }

          //SHOW LOADER
          store.dispatch('showLoader')

          //Lade User-Profil
          setTimeout(() => {
            let doc = firebase.firestore().collection('User').doc(user.uid)
            doc.get().then((doc) => {
              if (doc.exists) {
                user.userdata = doc.data()
                user.userdata.provider = providername
                user.userdata.uid = user.uid
                if (!user.userdata.vorname) {
                  user.fullfill = true
                }
                store.dispatch('fetchUser', user)
              } else {
                firebase.firestore().collection('User').doc(user.uid).set({
                  rolle: 'Zuschauer',
                })
                user.fullfill = true
                user.provider = providername
                store.dispatch('fetchUser', user)
              }
            })
          }, 200)
        } else {
          store.dispatch('anonymerUser')
        }
      } else {
        store.dispatch('fetchUser', user)
      }
    })
  })
} else {
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FBAPIKEY,
    authDomain: process.env.VUE_APP_FBAUTHDOMAIN,
    databaseURL: process.env.VUE_APP_FBDBURL,
    projectId: process.env.VUE_APP_FBPROJECTID,
    storageBucket: process.env.VUE_APP_FBSTORAGE,
    messagingSenderId: process.env.VUE_APP_FBMESSAGING,
    appId: process.env.VUE_APP_FBAPPID,
  }
  firebase.initializeApp(firebaseConfig)
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      if (!user.isAnonymous) {
        var userprovider = firebase.auth().currentUser

        // GET PROVIDER INFORMATIONS
        var providername = 'password'
        if (userprovider != null) {
          userprovider.providerData.forEach((profile) => {
            providername = profile.providerId
          })
        }

        //SHOW LOADER
        store.dispatch('showLoader')

        //Lade User-Profil
        setTimeout(() => {
          let doc = firebase.firestore().collection('User').doc(user.uid)
          doc.get().then((doc) => {
            if (doc.exists) {
              user.userdata = doc.data()
              user.userdata.provider = providername
              user.userdata.uid = user.uid
              if (!user.userdata.vorname) {
                user.fullfill = true
              }
              store.dispatch('fetchUser', user)
            } else {
              firebase.firestore().collection('User').doc(user.uid).set({
                rolle: 'Zuschauer',
              })
              user.fullfill = true
              user.provider = providername
              store.dispatch('fetchUser', user)
            }
          })
        }, 200)
      } else {
        store.dispatch('anonymerUser')
      }
    } else {
      store.dispatch('fetchUser', user)
    }
  })
}
