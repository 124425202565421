/* Teamübersicht */
const Teams = () => import('../components/admin/team/start.vue')

/* Team */
const Team = () => import('../components/admin/team/index.vue')
const Uebersicht = () => import('../components/admin/team/team.vue')
const MitgliedBearbeiten = () =>
  import('../components/admin/team/editMember.vue')
const TeamBearbeiten = () => import('../components/admin/team/editTeam.vue')
const TeamImport = () => import('../components/admin/team/csvimport.vue')

const teamroutes = {
  teams: {
    path: '/teams',
    component: Teams,
    meta: {
      requiresAuth: true,
    },
  },
  team: {
    path: '/team/:team',
    component: Team,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: Uebersicht,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'bearbeiten',
        component: TeamBearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'import',
        component: TeamImport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'mitglied/:mitglied',
        component: MitgliedBearbeiten,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
}

export default teamroutes
