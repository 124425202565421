/* Teams */
import teamroutes from './teams'

/* Verein */
import vereinroutes from './verein'

/* Veranstaltung */
import veranstaltungsroutes from './veranstaltung'

const Start = () => import('../components/admin/index.vue')
const Dashboard = () => import('../components/admin/dashboard.vue')
const CheckInControl = () =>
  import('../components/admin/einlasskontrolle/dashboard.vue')
const Anwesenheitslisten = () =>
  import('../components/admin/anwesenheitsliste/anwesenheitsliste.vue')
const Publicfullfill = () =>
  import('../components/public/profil-vervollstaendigen.vue')
const Gesundheitsamt_Anfragen = () =>
  import('../components/admin/gesundheitsamt/anfragen.vue')

const adminroutes = {
  admin: {
    path: '/',
    component: Start,
    children: [
      {
        path: '/',
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      vereinroutes,
      teamroutes.teams,
      teamroutes.team,
      veranstaltungsroutes.start,
      veranstaltungsroutes.veranstaltung,
      veranstaltungsroutes.wettkampf,
      {
        path: '/zuschauerkontrolle',
        component: CheckInControl,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ga/anfragen',
        component: Gesundheitsamt_Anfragen,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/zuschauerkontrolle/:sportstaette',
        component: CheckInControl,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/anwesenheitslisten',
        component: Anwesenheitslisten,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/mein-account',
        redirect: '/user',
      },
      {
        path: '/profil-vervollstaendigen',
        component: Publicfullfill,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
}

export default adminroutes
