<template>
  <v-container fluid>
    <!--<v-row justify="center" class="fixed-top text-center" style="height: 100%;">
            <v-col align-self="center">
                <svg width="200" height="200" viewBox="0 0 100 100">
                    <polyline class="line-cornered stroke-still" points="0,0 100,0 100,100" stroke-width="10" fill="none"></polyline>
                    <polyline class="line-cornered stroke-still" points="0,0 0,100 100,100" stroke-width="10" fill="none"></polyline>
                    <polyline class="line-cornered stroke-animation" points="0,0 100,0 100,100" stroke-width="10" fill="none"></polyline>
                    <polyline class="line-cornered stroke-animation" points="0,0 0,100 100,100" stroke-width="10" fill="none"></polyline>
                </svg>
                <h1>
                    LADEN
                </h1>
            </v-col>
    </v-row>-->
    <v-overlay class="text-center" :color="template.colors.primary" opacity="1">
      <v-row justify="center">
        <v-col cols="6" md="4" lg="3" class="pb-0">
          <v-img
            :src="`/img/${template.var.app_logo}`"
            contain
            :width="$vuetify.breakpoint.smAndDown ? '100vw' : undefined"
            content-class="img-fluid"
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto" class="pa-0" align-self="center">
          <v-progress-circular
            indeterminate
            width="4"
            size="40"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'loader',
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
}
</script>

<style scoped>
svg {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1);
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

.stroke-still {
  stroke: #232323;
}

.stroke-animation {
  -webkit-animation: stroke-spacing 1.2s ease-in, stroke-color 4.8s linear;
  animation: stroke-spacing 1.2s ease-in, stroke-color 4.8s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

@-webkit-keyframes stroke-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
}

@keyframes stroke-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
}

@-webkit-keyframes stroke-color {
  0% {
    stroke: rgb(81, 168, 0);
  }
  24% {
    stroke: #00e927;
  }
  25% {
    stroke: #2fff2f;
  }
  49% {
    stroke: #0b7010;
  }
  50% {
    stroke: #28a117;
  }
  74% {
    stroke: #3affef;
  }
  75% {
    stroke: #ffffff;
  }
  99% {
    stroke: #327844;
  }
}

@keyframes stroke-color {
  0% {
    stroke: rgb(81, 168, 0);
  }
  24% {
    stroke: #00e927;
  }
  25% {
    stroke: #2fff2f;
  }
  49% {
    stroke: #0b7010;
  }
  50% {
    stroke: #28a117;
  }
  74% {
    stroke: #3affef;
  }
  75% {
    stroke: #ffffff;
  }
  99% {
    stroke: #327844;
  }
}
</style>
