/* COMPONENTS */

const Impressum = () => import('../pages/impressum.vue')
const Datenschutz = () => import('../pages/datenschutz.vue')
const Releases = () => import('../pages/release_notes.vue')

const staticroutes = {
  impressum: {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  privacy: {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  releasenotes: {
    path: '/releases',
    name: 'Release-Notes',
    component: Releases,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
}

export default staticroutes
